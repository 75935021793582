import React, { useEffect, useRef, useState } from "react";
import FTIcon from "react-file-type-icons";
import moment from "moment";
import { GET, POST, PUT } from "../../chatapp/apis/constants";
import { Formik, Form, Field, ErrorMessage } from "formik";
import styled from "styled-components";
import { BaseUrl, commonMediaUpload } from "../../chatapp/apis/Networks";
import "moment-timezone";
import {
  ModalHeader,
  ModalBody,
  Grid,
  GridItem,
  Button,
  Input,
  CloseButton,
} from "@chakra-ui/react";
import { HttpCallPut } from "../../chatapp/apis/UseHttp";
import toast from "react-hot-toast";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/form-control";
import { AttachmentCont, FileTypeDiv, LoaderDiv, TButton } from "./trelloStyle";
import MoveCard from "./moveCard";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../Redux";
import { SpinLoader } from "../../Components";
import { Spin } from "antd";
import CurrentService from "./currentService";
import CadIcon from "../../Assets/Icons/cad.png";
import CollectFeedBack from "./CollectFeedBack";
import ChatOnWhatsapp from "./ChatOnWhatsapp";

const MainDiv = styled.div`
height: 25em;
overflow-y: auto;
.modal-body {
  overflow: auto;
`;
const axios = require("axios");

export default function TrelloModelViews(props) {
  const [viewType, setViewType] = useState(props.type);
  const [fileValue, setFileValue] = useState("");
  const [isLoader, setLoader] = useState(false);
  const [mediaFile, setMediafile] = useState("");
  const [attachmentLimit, setAttachementLimt] = useState(10);
  const orderCode = props.projectCode;
  const [attachementList, setAttachementList] = useState([]);
  const state = useSelector((state) => state);
  const [attachementPage, SetAttachementPage] = useState(1);
  const [imageDataArray, setImageDataArray] = useState([]);
  const dispatch = useDispatch();
  const { trelloAttachmentList, trelloCommentList } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const [imgData, setImgData] = useState({
    url: "",
  });
  const [imgDataList, setImgDataList] = useState([]);
  useEffect(() => {
    trelloAttachmentList(props.id, attachmentLimit, attachementPage);
    //trelloCommentList(id,'');
  }, [attachementPage]);

  useEffect(() => {
    if (state.trelloAttachmentList?.attachmentList !== "") {
      // setAttachementList(
      //   state?.trelloAttachmentList?.attachmentList.map((item) => item)
      // );
      let attachements = [
        ...attachementList,
        ...state?.trelloAttachmentList?.attachmentList.map((item) => item),
      ];
      const uniqueIds = [];
      setAttachementList(
        attachements
          .filter((element) => {
            const isDuplicate = uniqueIds.includes(element._id);
            //console.log(element._id)
            if (!isDuplicate) {
              uniqueIds.push(element._id);

              return true;
            }

            return false;
          })
          .map((item) => item)
      );
      // console.log(attachements)
    }
  }, [state]);

  const Heading =
    viewType == "Attachments"
      ? "All Attachments"
      : viewType == "Payment"
      ? "Send Trello Payment"
      : viewType == "Notification"
      ? " Send Trello Notification"
      : viewType == "MoveCard"
      ? "Move Trello Card"
      : viewType == "CollectFeedBack"
      ? "Collect FeedBack"
      : viewType == "ChatOnWhatsapp"
      ? "Chat On Whatsapp"
      : viewType == "currentService"
      ? " Current Service"
      : "";
  useEffect(() => {
    if (viewType == "Attachments") {
      trelloAttachmentList(props.id, attachmentLimit, attachementPage);
    }
  }, []);

  const uploadFileApi = (event, type) => {
    if (type == "multiple") {
      const data = [...imageDataArray];
      console.log("length", data.length);
      if (
        event.target?.files?.length <= 5 &&
        imageDataArray.length + event.target?.files?.length <= 5
      ) {
        for (let i = 0; i < event.target?.files?.length; i++) {
          data.push(event.target.files[i]);
        }
        setImageDataArray(data);
      } else {
        for (let i = imageDataArray.length, val = 0; i < 5; i++, val++) {
          data.push(event.target.files[val]);
        }
        setImageDataArray(data);
        toast.error("You have reached your maximum limit to Select File!", {
          position: "top-right",
        });
      }

      console.log("imageDataArray", imageDataArray);
      console.log(data);
    } else {
      setMediafile(event.target.files[0]);

      setLoader(true);
      let data = {
        media: event.target.files[0],
        entityType: "order",
        entityId: orderCode,
        acl: "public",
        returnType: "url",
      };
      var formData = new FormData();
      for (var i in data) {
        formData.append(i, data[i]);
      }
      axios({
        method: POST,
        url: BaseUrl + commonMediaUpload,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": `${localStorage.getItem("token")}`,
        },
        data: formData,
      })
        .then((response) => {
          console.log("response.data", response);
          setImgData({
            url: response.data.url,
          });
          setImgDataList([...imgDataList, response.data.url]);
          setLoader(false);
          setFileValue("");
        })
        .catch((err) => {
          toast.error(err, {
            position: "top-right",
          });
          setLoader(false);
        });
    }
  };
  const TrelloCommandSubmit = (type, values) => {
    console.log(values);
    const userToken = localStorage.getItem("token");
    var newItem;
    if (type == "Notification") {
      newItem = {
        ordercode: orderCode,
        command: "info",
        title: values.notificationtitle,
        description: values.notificationdescription,
        attachments: imgDataList,
        urlName: values.notificationUrlName,
        urlLink: values.notificationUrlLink,
      };
    } else if (type == "Payment") {
      newItem = {
        ordercode: orderCode,
        command: "payment",
        amount: values.amount,
        title: values.paymenttitle,
        description: values.paymentdescription,
        attachments: imgDataList,
      };
    }

    newItem.projectId = props.projectId;
    HttpCallPut(`${"internal/trello/command"}`, POST, userToken, newItem)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Form Submitted successfully.", {
            position: "top-right",
          });
          console.log(res);
          setImgDataList([]);
          props.onClose();
          trelloAttachmentList(props.id, attachmentLimit, attachementPage);
          trelloCommentList(props.id, 20, 1);

          //   AddTrelloAttachment();
        }
      })
      .catch((err) => {
        toast.error("Oops! Form not Submitted.", {
          position: "top-right",
        });
        props.onClose();
        setImgDataList([]);
        console.log("rt", err);
      });
  };
  return (
    <div>
      {" "}
      <ModalHeader style={{ justifyContent: "space-between", display: "flex" }}>
        {Heading}{" "}
        <div>
          <CloseButton
            onClick={() => {
              props.onClose();
            }}
          />
        </div>
      </ModalHeader>
      {viewType == "Attachments" ? (
        <ModalBody>
          <AttachmentCont>
            {/* {props.data && ( */}
            <MainDiv>
              <Grid
                templateColumns={
                  window.innerWidth > 1200 ? "repeat(3, 1fr)" : "repeat(2, 1fr)"
                }
                gap={3}
              >
                {attachementList?.map((file, index) => (
                  <>
                    <GridItem w="100%">
                      <div className="ap-flex ap-w100 ap-p5">
                        <div
                          className="ap-flex-center  ap-flex "
                          style={{
                            backgroundColor: "#f8f9fa",
                            alignItems: "center",
                            padding: "15px",
                          }}
                        >
                          {file?.filePath?.split(".").pop() !==
                          ("cad" || "CAD") ? (
                            <FTIcon
                              fileName={
                                file?.filePath?.split(".")?.pop() || "url"
                              }
                              style={{ fontSize: "40px" }}
                              color="#2b6b2a"
                              fileType={"url"}
                            />
                          ) : (
                            <img
                              src={CadIcon}
                              style={{ width: "30px" }}
                              alt=""
                            />
                          )}
                        </div>
                        <div className="ap-flex-end ap-w75 ap-p10">
                          <span>
                            {" "}
                            <a
                              href={
                                file?.fileName.includes("https")
                                  ? file?.fileName
                                  : BaseUrl +
                                    "internal/storage/" +
                                    file?.fileName +
                                    "?mediaUrl=1"
                              }
                              target="_blank"
                            >
                              <p
                                className="file-title"
                                style={{ cursor: "pointer" }}
                              >
                                {file?.originalName || file?.fileName}
                              </p>
                            </a>
                          </span>
                          <span className="file-add-date">
                            Added{" "}
                            <span title={file.datetime}>
                              {moment
                                .utc(file.datetime)
                                .local()
                                .format("MMM D, YYYY [at] h:mm A z")}
                            </span>
                          </span>
                        </div>
                      </div>
                    </GridItem>
                  </>
                ))}
              </Grid>
              {state?.trelloAttachmentList?.loading == true && (
                <div
                  style={{
                    minHeight: "20px",
                    // overflow: "auto",
                  }}
                >
                  <div
                    className="ap-flex ap-w100 ap-flex-center-center"
                    style={{ height: "40px" }}
                  >
                    <Spin tip="Fetching Records....." />
                  </div>
                </div>
              )}
              {attachementList?.length <
                state?.trelloAttachmentList?.totalRecords && (
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <TButton
                    onClick={() => {
                      SetAttachementPage(attachementPage + 1);
                    }}
                  >
                    View More
                  </TButton>
                </div>
              )}
            </MainDiv>
          </AttachmentCont>
        </ModalBody>
      ) : viewType == "Payment" ? (
        <ModalBody>
          <Formik
            initialValues={{
              paymenttitle: "",
              amount: "",
              paymentdescription: "",
              notificationdescription: "",
            }}
            onSubmit={(values) => TrelloCommandSubmit("Payment", values)}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <Field name="name">
                  {({ field, form }) => (
                    <>
                      <FormControl>
                        <FormLabel htmlFor="text-input">Title</FormLabel>
                        <Input
                          type="text"
                          id="paymenttitle"
                          name="paymenttitle"
                          placeholder="Enter Title"
                          onChange={(e) => {
                            setFieldValue("paymenttitle", e.target.value);
                          }}
                          required
                        />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="text-input">Amount</FormLabel>
                        <Input
                          type="number"
                          id="amount"
                          name="amount"
                          placeholder="Enter Amount"
                          onChange={(e) => {
                            setFieldValue("amount", e.target.value);
                          }}
                          required
                        />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="text-input">Description</FormLabel>
                        <Input
                          type="textarea"
                          id="paymentdescription"
                          name="paymentdescription"
                          placeholder="Description"
                          onChange={(e) => {
                            setFieldValue("paymentdescription", e.target.value);
                          }}
                        />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="text-input">Attachments</FormLabel>
                        <div style={{ display: "flex", padding: " 10px" }}>
                          {imgDataList
                            ? imgDataList.map((row, i) => {
                                var fileExtension = row?.url?.split(".").pop();
                                var filename = row?.split("/").pop();
                                console.log("teeer", fileExtension);
                                return (
                                  <p
                                    style={{
                                      marginTop: 10,
                                      width: "14%",
                                      marginLeft: "20px",
                                    }}
                                  >
                                    <FileTypeDiv className="ap-flex-center  ap-flex ">
                                      {row?.split(".").pop() !==
                                      ("cad" || "CAD") ? (
                                        <FTIcon
                                          fileName={
                                            row?.split(".")?.pop() || "url"
                                          }
                                          style={{ fontSize: "40px" }}
                                          fileType={"url"}
                                          color="#2b6b2a"
                                        />
                                      ) : (
                                        <img
                                          src={CadIcon}
                                          style={{ width: "30px" }}
                                          alt=""
                                        />
                                      )}
                                    </FileTypeDiv>
                                    <p
                                      style={{ color: "black", padding: "5px" }}
                                    >
                                      {filename}
                                    </p>
                                  </p>
                                );
                              })
                            : null}
                          {isLoader == true ? (
                            <LoaderDiv>
                              <SpinLoader />
                            </LoaderDiv>
                          ) : (
                            ""
                          )}
                        </div>
                        <Input
                          type="file"
                          id="paymentattachment"
                          name="paymentattachment"
                          placeholder="Name"
                          onChange={(e) => {
                            uploadFileApi(e);
                          }}
                        />
                      </FormControl>
                    </>
                  )}
                </Field>
                <Button
                  mt={4}
                  className="submit_right1"
                  isLoading={isSubmitting}
                  type="submit"
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      ) : viewType == "Notification" ? (
        <ModalBody>
          <Formik
            initialValues={{
              notificationtitle: "",
              notificationUrlName: "",
              notificationUrlLink: "",
              notificationdescription: "",
            }}
            onSubmit={(values) => TrelloCommandSubmit("Notification", values)}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <Field name="name">
                  {({ field, form }) => (
                    <>
                      <FormControl>
                        <FormLabel htmlFor="text-input">Title</FormLabel>
                        <Input
                          type="text"
                          id="notificationtitle"
                          name="notificationtitle"
                          placeholder="Title"
                          onChange={(e) => {
                            setFieldValue("notificationtitle", e.target.value);
                          }}
                          required
                        />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="text-input">Link Name</FormLabel>
                        <Input
                          type="text"
                          id="notificationUrlName"
                          name="notificationUrlName"
                          placeholder="Name"
                          onChange={(e) => {
                            setFieldValue(
                              "notificationUrlName",
                              e.target.value
                            );
                          }}
                        />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="text-input">Link</FormLabel>
                        <Input
                          type="text"
                          id="notificationUrlLink"
                          name="notificationUrlLink"
                          placeholder="Url"
                          onChange={(e) => {
                            setFieldValue(
                              "notificationUrlLink",
                              e.target.value
                            );
                          }}
                        />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="text-input">Description</FormLabel>
                        <Input
                          type="textarea"
                          id="notificationdescription"
                          name="notificationdescription"
                          placeholder="Description"
                          onChange={(e) => {
                            setFieldValue(
                              "notificationdescription",
                              e.target.value
                            );
                          }}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="text-input">Attachments</FormLabel>
                        <div style={{ display: "flex", padding: " 10px" }}>
                          {imgDataList
                            ? imgDataList.map((row, i) => {
                                var fileExtension = row?.url?.split(".").pop();
                                console.log("teeer", fileExtension);
                                var filename = row?.split("/").pop();
                                return (
                                  <p
                                    style={{
                                      marginTop: 10,
                                      width: "14%",
                                      marginLeft: "20px",
                                    }}
                                  >
                                    {/* <img
                                    className="img-fluid"
                                    src={row?.url}
                                    id="preview-image"
                                    alt=""
                                    style={{
                                      height: "45px",
                                      width: "45px",
                                      objectFit: "cover",
                                    }}
                                  /> */}
                                    <FileTypeDiv className="ap-flex-center  ap-flex ">
                                      {row?.split(".").pop() !==
                                      ("cad" || "CAD") ? (
                                        <FTIcon
                                          fileName={
                                            row?.split(".")?.pop() || "url"
                                          }
                                          style={{ fontSize: "40px" }}
                                          fileType={"url"}
                                          color="#2b6b2a"
                                        />
                                      ) : (
                                        <img
                                          src={CadIcon}
                                          style={{ width: "30px" }}
                                          alt=""
                                        />
                                      )}
                                    </FileTypeDiv>
                                    <p
                                      style={{ color: "black", padding: "5px" }}
                                    >
                                      {filename}
                                    </p>
                                  </p>
                                );
                              })
                            : null}
                          {isLoader == true ? (
                            <LoaderDiv>
                              <SpinLoader />
                            </LoaderDiv>
                          ) : (
                            ""
                          )}
                        </div>
                        <Input
                          type="file"
                          id="notificationattachment"
                          name="notificationattachment"
                          placeholder="Name"
                          onChange={(event) => uploadFileApi(event)}
                        />
                      </FormControl>
                    </>
                  )}
                </Field>
                <Button
                  mt={4}
                  className="submit_right1"
                  isLoading={isSubmitting}
                  type="submit"
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      ) : viewType == "MoveCard" ? (
        <ModalBody>
          <MoveCard
            id={props.id}
            onClose={props.onClose}
            boardId={props?.boardId}
            listId={props?.listId}
            SetCommentPage={props.SetCommentPage}
          />
        </ModalBody>
      ) : viewType == "currentService" ? (
        <ModalBody>
          <CurrentService
            id={props.id}
            onClose={props.onClose}
            SetCommentPage={props.SetCommentPage}
          />
        </ModalBody>
      ) : viewType == "CollectFeedBack" ? (
        <ModalBody>
          <CollectFeedBack onClose={props.onClose} />
        </ModalBody>
      ) : viewType == "ChatOnWhatsapp" ? (
        <ChatOnWhatsapp
          onClose={props.onClose}
          SetCommentPage={props.SetCommentPage}
          projectCode={orderCode}
          projectId={props.projectId}
        />
      ) : (
        ""
      )}
    </div>
  );
}
