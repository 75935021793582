import React, { useEffect } from 'react';
import './App.scss';
import './Styles/Master.scss';
import firebase from 'firebase';
import firebaseConfig from '../src/chatapp/views/chat/config';
import Routes from './Routes/index';
import { actionCreators } from "./Redux"
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { ChakraProvider } from '@chakra-ui/react';
import { Toaster } from 'react-hot-toast';
import runOneSignal  from './Utils/webpush';

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}else {
    firebase.initializeApp(firebaseConfig); // if already initialized, use that one
}

function App() {
  const state = useSelector(state=>state)
  const dispatch = useDispatch();
  const { products2,customersList,admins,country,phoneCountryInput} = bindActionCreators(actionCreators,dispatch)

  useEffect(()=>{
    customersList('q=')
    // admins()
    country()
    phoneCountryInput()
    products2()
    runOneSignal()
  },[])

  useEffect(()=>{
    console.log(">>>REDUX",state)
  },[state])
  
  return (

    <ChakraProvider>
      <Toaster/>
      <div>
      {<Routes />}
      </div>
    </ChakraProvider>
  );
}

export default App;
