// export const BaseUrl = "https://api-sandbox.makemyhouse.com/"
// export const FIRESTORE_KEY_NAME="ORDERS-SANDBOX"
// export const FIRESTORE_KEY = "mmh-consumer-sandbox"
// export const gobackUrl = 'http://admin-testing.makemyhouse.com/#/'

// production

export const FIRESTORE_KEY_NAME = "ORDERS"
export const FIRESTORE_KEY = "mmh-consumer"
export const BaseUrl = "https://api.makemyhouse.com/"
export const gobackUrl = 'http://admin.makemyhouse.com/#/'
export const onesignal = '855d0842-9716-4b3b-a9af-7aa960b38524'
export const crmUrl ="http://crm2.makemyhouse.com/"

// testing 
// export const FIRESTORE_KEY_NAME = "ORDERS-TESTING"
// export const FIRESTORE_KEY = "mmh-consumer-testing"
// export const BaseUrl = "https://api-uat.makemyhouse.com/"
// export const gobackUrl = 'http://admin-testing.makemyhouse.com/#/'
// export const onesignal = '90cf5df4-4b66-4bac-bf8d-d53360bc9d5d'
// export const crmUrl ="http://crm2.makemyhouse.com/"

//export const gobackUrl = 'http://localhost:3000/#/'

//sandbox
// export const trelloBaseUrl = 'https://trello.com/1/authorize?expiration=never&name=MyPersonalToken&scope=read,write&response_type=token&key=6862df56e3522a842ef862dd84f70a44&return_url=http://localhost:3000/dashboard'

//testing
// export const trelloBaseUrl = 'https://trello.com/1/authorize?expiration=never&name=MyPersonalToken&scope=read,write&response_type=token&key=6862df56e3522a842ef862dd84f70a44&return_url=https://admin-testing.makemyhouse.com/dashboard'
//production
export const trelloBaseUrl =
  "https://trello.com/1/authorize?expiration=never&name=MyPersonalToken&scope=read,write&response_type=token&key=6862df56e3522a842ef862dd84f70a44&return_url=https://admin.makemyhouse.com/dashboard";

export const LoginUrl = "internal/me/login";
export const Orders = "internal/orders";
export const GetChatList = "internal/chat/";
export const GetAdmins = "internal/order/admins/";
export const TransferChat = "internal/chat/transferChat/";
export const TransferChats = "internal/chat/transfer/";
export const GetAttechmntes = "internal/media/cardAttachments/"; //"internal/media/listAttachments/"
export const GetChat = "internal/chat/";
export const leadChat = "internal/chat/leadChat";
export const UploadFile = "internal/media";
export const GetAminList = "internal/user/admins/";
export const assignManager = "internal/user/assignManager";
export const OrderListDetails = "customer/Orders";
export const admminList = "internal/users";
export const IsOrderAllow = "internal/chat/isOrderAllow/";

export const pendingApprovals = "internal/Orders/pendingAttachments";

export const attachAndMove = "internal/Orders/attachMove";

export const dismissApproval = "internal/orders/dismissNotification";

export const pendingOrders = "internal/Orders/pendingOrders";

export const UploadMedia = "internal/media/uploadMedia";
export const orderDetails = "internal/order/details/";
export const clientList = "internal/customers";
export const clientDetalsi = "internal/customer/";
export const generateInvoice = "internal/invoice/generate/";
export const trelloAuth = "internal/me/oauth";
export const raisePaymentRequest = "internal/Paymentrequest";
export const paymentRequestList = "internal/Paymentrequest/";
export const commonMediaUpload = "internal/media/upload";
export const transactionList = "internal/payments";
export const getProductServices = "public/productsv2";
export const GetTaskList = "internal/tasks";
export const GetEntityList = "internal/task/entities";
export const GetTaskTypeList = "internal/task/types";
export const AddCommentOnTask = "internal/task/comment/";
export const downlineAdmins = "internal/user/admins";
export const masterProjects = "internal/projects/";
export const listdraftOrder = "internal/orders/draftOrder";
export const dlTemplate = "internal/messaging/SmsdltTemplate";
export const BulkSms = "internal/VtigerLead/sendSms";
export const generatePaymentLink = "internal/payment/link/";
export const entityList = "internal/entity";
// 'public/entity'
export const entityGateways =
  // "/internal/entity/gateway/"
  "internal/entity/gateway/";
// 'public/entity/gateways/MMHDS'
export const vtigerLeadCount = "internal/VtigerLead/leadCount";
export const cancelOrder = "internal/Orderv2/cancelOrder/";
export const duplicateOrder = "internal/Orderv2/duplicate/";
export const activateServiceManually = "internal/orderv2/activateOrderService/";
export const promoCoupon = "internal/coupon";
export const countryList = "/public/geolist";
export const stateList = "public/geolist/states/";
export const contractReminder = "internal/order/contractReminder?orderCode=";
