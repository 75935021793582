import React, { useEffect, useState } from "react";
import {
  ActionDiv,
  FDiv,
  FHeading,
  FItem,
} from "./interioriDesignListingStyle";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  HStack,
  Radio,
} from "@chakra-ui/react";
import { Input, Select } from "antd";

export default function UtecProjectFilter(props) {
  const [formData, setFormData] = useState();
 

  // useEffect(() => {
  //   if (formData.category)
  //     props.getSubCategoriesList(`category=${formData.category}`);
  // }, [formData.category]);
  useEffect(() => {
    props.setFilterData(formData);
  }, [formData]);
  const { Option } = Select;

  const handleSearchForm = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <>
      <FHeading>
        Filters
        <ActionDiv>
          <span
            style={{
              color: "#7a7ae6",
              fontSize: "16px",
              cursor: "pointer",
              padding: "0 5px",
            }}
            onClick={() => {
              setFormData({});
              props.setFilterClear(1);
            }}
          >
            Clear
          </span>
          <span
            style={{
              color: "#7a7ae6",
              fontSize: "16px",
              cursor: "pointer",
              padding: "0 5px",
            }}
            onClick={() => {
              const searchQuery = Object.entries(formData)
                .map(([key, value]) => `${key}=${value}`)
                .join("&");

              props.interiorDesignList(
                `limit=50&offset=0&page=1&${searchQuery}`
              );
            }}
          >
            Search
          </span>
        </ActionDiv>
      </FHeading>
      <FDiv>
        <Accordion allowToggle defaultIndex={[0]}>
          <AccordionItem>
            <h2>
              <AccordionButton
                style={{ fontWeight: "500", color: "var(--primary-green)" }}
              >
                <Box flex="1" textAlign="left">
                  Primary Filters
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">Project ID</FormLabel>
                  <Input
                    allowClear
                    value={formData?.projectID || ""}
                    placeholder="Enter Project ID..."
                    name="projectID"
                    onChange={handleSearchForm}
                  />
                </FormControl>
              </FItem>
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">
                    Width <span style={{ fontStyle: "italic" }}>(in FT)</span>
                  </FormLabel>
                  <span className="f-sub-group">
                    <FormControl as="fieldset" className="f-sub-input-group">
                      <span className="f-inline-field">
                        {/* <FormLabel className="f-lable">min</FormLabel> */}
                        <Input
                          allowClear
                          placeholder="min Width..."
                          name="width"
                          value={formData?.width}
                          onChange={(event) => handleSearchForm(event)}
                        />
                      </span>
                    </FormControl>
                    <span style={{ fontWeight: "800" }}>-</span>
                    <FormControl as="fieldset" className="f-sub-input-group">
                      <span className="f-inline-field">
                        {/* <FormLabel
                          className="f-lable"
                          style={{ paddingLeft: "5px" }}
                        >
                          max
                        </FormLabel> */}
                        <Input
                          allowClear
                          placeholder="max Width..."
                          name="maxwidth"
                          value={formData?.maxwidth}
                          onChange={(event) => handleSearchForm(event)}
                        />
                      </span>
                    </FormControl>
                  </span>
                </FormControl>
              </FItem>
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">
                    Depth <span style={{ fontStyle: "italic" }}>(in FT)</span>
                  </FormLabel>
                  <span className="f-sub-group">
                    <FormControl as="fieldset" className="f-sub-input-group">
                      <span className="f-inline-field">
                        {/* <FormLabel className="f-lable">min</FormLabel> */}
                        <Input
                          allowClear
                          placeholder="min Depth..."
                          name="depth"
                          value={formData?.depth}
                          onChange={(event) => handleSearchForm(event)}
                        />
                      </span>
                    </FormControl>
                    <span style={{ fontWeight: "800" }}>-</span>

                    <FormControl as="fieldset" className="f-sub-input-group">
                      <span className="f-inline-field">
                        {/* <FormLabel
                          className="f-lable"
                          style={{ paddingLeft: "5px" }}
                        >
                          max
                        </FormLabel> */}
                        <Input
                          allowClear
                          placeholder="max Depth..."
                          name="maxdepth"
                          value={formData?.maxdepth}
                          onChange={(event) => handleSearchForm(event)}
                        />
                      </span>
                    </FormControl>
                  </span>
                </FormControl>
              </FItem>
              {/* <FItem>
                <span className="f-check-group">
                  <FormControl as="fieldset" className="f-input-group">
                    <FormLabel className="f-lable">Width</FormLabel>
                    <Input
                      allowClear
                      placeholder="Enter Width..."
                      name="width"
                      value={formData?.width}
                      onChange={(event) => handleSearchForm(event)}
                    />
                   
                  </FormControl>
                  <FormControl
                    as="fieldset"
                    className="f-input-group"
                    style={{ marginLeft: "2px" }}
                  >
                    <FormLabel className="f-lable">Depth</FormLabel>
                    <Input
                      allowClear
                      placeholder="Enter Depth..."
                      name="depth"
                      value={formData?.depth}
                      onChange={(event) => handleSearchForm(event)}
                    />
                  
                  </FormControl>
                </span>
              </FItem> */}
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">
                    Area <span style={{ fontStyle: "italic" }}>(in FT)</span>
                  </FormLabel>
                  <span className="f-sub-group">
                    <FormControl as="fieldset" className="f-sub-input-group">
                      <span className="f-inline-field">
                        <Input
                          allowClear
                          placeholder="min buildUpArea..."
                          name="buildUpArea"
                          value={formData?.buildUpArea}
                          onChange={(event) => handleSearchForm(event)}
                        />
                      </span>
                    </FormControl>
                    <span style={{ fontWeight: "800" }}>-</span>

                    <FormControl as="fieldset" className="f-sub-input-group">
                      <span className="f-inline-field">
                        {/* <FormLabel
                          className="f-lable"
                          style={{ paddingLeft: "5px" }}
                        >
                          max
                        </FormLabel> */}
                        <Input
                          allowClear
                          placeholder="max buildUpArea..."
                          name="maxbuildUpArea"
                          value={formData?.maxbuildUpArea}
                          onChange={(event) => handleSearchForm(event)}
                        />
                      </span>
                    </FormControl>
                  </span>
                </FormControl>
              </FItem>
              <FItem>
                <span className="f-check-group">
                  <FormControl
                    as="fieldset"
                    className="f-input-group"
                    style={{ marginLeft: "2px" }}
                  >
                    <FormLabel className="f-lable">Floors</FormLabel>
                    <Input
                      allowClear
                      placeholder="Enter Floors..."
                      name="floor"
                      value={formData?.floor}
                      onChange={(event) => handleSearchForm(event)}
                    />
                  </FormControl>
                </span>
              </FItem>
              {/* <FItem>
                <span className="f-check-group">
                  <FormControl as="fieldset" className="f-input-group">
                    <FormLabel className="f-lable">Category</FormLabel>
                    <Select
                      size="medium"
                      labelInValue
                      placeholder="Select Category"
                      style={{ width: "98%" }}
                      value={formData?.category}
                      onChange={(d) =>
                        handleSearchForm({
                          target: { name: "category", value: d.value },
                        })
                      }

                      // filterOption={false}
                    >
                      {props?.categories?.map((row, rowIndex) => (
                        <Option value={row} key={rowIndex}>
                          {row}
                        </Option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl as="fieldset" className="f-input-group">
                    <FormLabel className="f-lable">Subcategory</FormLabel>
                    <Select
                      size="medium"
                      labelInValue
                      placeholder="Select Sub Category"
                      style={{ width: "98%" }}
                      value={formData?.subcategory}
                      disabled={!formData?.category}
                      onChange={(d) =>
                        handleSearchForm({
                          target: { name: "subcategory", value: d.value },
                        })
                      }

                      // filterOption={false}
                    >
                      {props?.subCategories?.map((row, rowIndex) => (
                        <Option value={row} key={rowIndex}>
                          {row}
                        </Option>
                      ))}
                    </Select>
                  </FormControl>
                </span>
              </FItem> */}
              {/* <FItem>
                <FormControl as="fieldset" className="p-check-group">
                  <FormLabel className="f-lable">Image Type</FormLabel>
                  <RadioGroup
                    name="imagetype"
                    onChange={(e) => {
                      handleSearchForm({
                        target: { name: "imagetype", value: e },
                      });
                    }}
                    value={formData.imagetype}
                  >
                    <span
                      className="f-radio-button"
                      style={{ margin: "0 0.5rem" }}
                    >
                      <Radio value="2D">2D</Radio>
                    </span>
                    <span
                      className="f-radio-button"
                      style={{ margin: "0 0.5rem" }}
                    >
                      <Radio value="3D">3D</Radio>
                    </span>
                    <span
                      className="f-radio-button"
                      style={{ margin: "0 0.5rem" }}
                    >
                      <Radio value="any">Any</Radio>
                    </span>
                  </RadioGroup>
                </FormControl>
              </FItem> */}
              {/* <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">Project Type</FormLabel>
                  <RadioGroup
                    name="projectType"
                    onChange={(e) => {
                      handleSearchForm({
                        target: { name: "projectType", value: e },
                      });
                    }}
                  >
                    <span
                      className="f-radio-button"
                      style={{ margin: "0 0.5rem" }}
                    >
                      {" "}
                      <Radio value="RESIDENTIAL">Residential</Radio>
                    </span>
                    <span
                      className="f-radio-button"
                      style={{ margin: "0 0.5rem" }}
                    >
                      <Radio value="COMMERCIAL">Commercial</Radio>
                    </span>
                  </RadioGroup>
                </FormControl>
              </FItem> */}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </FDiv>
    </>
  );
}
