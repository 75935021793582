import React, { useEffect, useState } from "react";
import { ActionDiv, FDiv, FHeading, FItem } from "./projectCatlogStyle";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  HStack,
  Radio,
  FormHelperText,
} from "@chakra-ui/react";
import { Input, Checkbox, Slider } from "antd";
import { actionCreators } from "../../Redux";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory, useLocation } from "react-router-dom";

export default function CatlogFilter(props) {
  const [formData, setFormData] = useState({});
  const [groundFloor, setGroundFloor] = useState({});
  const [firstFloor, setFirstFloor] = useState({});
  const [secondFloor, setsecondFloor] = useState({});
  const [isToolTIp, setIsToolTIp] = useState(true);
  const [stairCaseCheckedList, setStairCaseCheckedList] = useState("");
  const [directionCheckedList, setSDirectionCheckedList] = useState("");
  const [groundCheckedList, setGroundCheckedList] = useState([]);
  const [firstCheckedList, setFirstCheckedList] = useState([]);
  const [secondCheckedList, setSecondCheckedList] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const { trelloCardInfo } = bindActionCreators(actionCreators, dispatch);
  const state = useSelector((state) => state);
  const [clearFilter, setFilterClear] = useState(props.clearFilter);
  const params = new URLSearchParams(location.search);
  const history = useHistory();
  const directions = [
    { value: "EE", label: "East " },
    { value: "WW", label: "West" },
    { value: "NN", label: "North" },
    { value: "SS", label: "South" },
    { value: "NE", label: "North-East" },
    { value: "NW", label: "North-West" },
    { value: "SE", label: "South-East" },
    { value: "SW", label: "South-West" },
  ];
  const stareCaseType = [
    { value: 1, label: "Internal" },
    { value: 2, label: "External" },
  ];
  const kitchenType = [
    { value: "1", label: "Open" },
    { value: "2", label: "Close" },
  ];
  const PreviewType = [
    { value: "true", label: "Yes" },
    { value: "false", label: "NO" },
    { value: "", label: "Any" },

  ];

  const commonAreaType = [
    { value: "1", label: "Living Come Dining " },
    { value: "2", label: "Living room and drawing area separate" },
    { value: "3", label: "Drawing living and dining area separate" },
    { value: "5", label: "Only living area (no dining)" },
  ];

  const checkBoxOptions = [
    { value: "washArea", label: "Wash Area" },
    { value: "office", label: "Office" },
    { value: "shop", label: "Shop" },
    { value: "poojaRoom", label: "Pooja Room" },
    { value: "garden", label: "Garden" },
    { value: "lift", label: "Lift" },
    { value: "commonBathroom", label: "Common Bathroom" },
    { value: "Basement", label: "Basement" },
    { value: "dressingArea", label: "Dressing Area" },
    { value: "Parking", label: "Parking" },
    { value: "gym", label: "gym" },
    { value: "Balcony", label: "Balcony" },
    { value: "recreationArea", label: "Recreation Area" },
    { value: "theatre", label: "Theatre" },
  ];
  useEffect(() => {
    console.log("formData", formData);
    props.setFilterData(formData);
  }, [formData]);

  const handleSearchForm = (event, floorNo) => {
    console.log(event.target.name, event.target.value, "fllorNO", floorNo);
    console.log(groundFloor, firstFloor, secondFloor);

    if (floorNo == 0) {
      setGroundFloor((prev) => {
        prev = JSON.parse(JSON.stringify(prev));
        prev[`${event.target.name}`] = event.target.value;
        prev["floorNo"] = 0;
        return prev;
      });
    } else if (floorNo == 1) {
      setFirstFloor((prev) => {
        prev = JSON.parse(JSON.stringify(prev));
        prev[`${event.target.name}`] = event.target.value;
        prev["floorNo"] = 1;

        return prev;
      });
    } else if (floorNo == 2) {
      setsecondFloor((prev) => {
        prev = JSON.parse(JSON.stringify(prev));
        prev[`${event.target.name}`] = event.target.value;
        prev["floorNo"] = 2;

        return prev;
      });
    } else {
      console.log("name", event.target.name);
      setFormData((prev) => {
        prev = JSON.parse(JSON.stringify(prev));
        prev[`${event.target.name}`] = event.target.value;
        return prev;
      });
    }
  };
  useEffect(() => {
    console.log(firstFloor, groundFloor, secondFloor);

    const data = [];

    data.push(groundFloor);
    data.push(firstFloor);
    data.push(secondFloor);

    setFormData((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      prev[`requirementForfloors`] = JSON.stringify(data);
      return prev;
    });

    console.log("data", JSON.stringify(data));
  }, [firstFloor, groundFloor, secondFloor]);
  useEffect(() => {
    if (params.get(`projectID`) && clearFilter !== 1) {
      console.log("hhhhh", formData.projectID);
      trelloCardInfo(params.get(`projectID`));
    }
  }, [params.get(`projectID`)]);

  const setFloorFilterData = (floordata, floor) => {
    let data = floordata;
    Object.entries(data).map(([key, value], formDataIndex) => {
      if (value == false) {
        delete data[key];
      }
      if (key == "kitchen" || key == "commonAreaType") {
        data[key] = value;
      }
    });
    let checklist = Object.keys(data);
    setGroundCheckedList(checklist);
    //  console.log("data",data,'info?.requirementForfloors[i]',info?.requirementForfloors[i]);
    if (floor == 0) {
      setGroundFloor(data);
    } else if (floor == 1) {
      setFirstFloor(data);
    } else {
      setsecondFloor(data);
    }
  };
  useEffect(() => {
    if (state.trelloCardInfo?.cardInfo) {
      let info = state.trelloCardInfo?.cardInfo;

      setFormData((prev) => {
        prev = JSON.parse(JSON.stringify(prev));
        if (params.get(`projectID`)) {
          prev["projectID"] = info?.projectID || "";
        }
        prev[`width`] = info?.width || "";
        prev[`floors`] = info?.floor || "";
        prev[`depth`] = info?.depth || "";
        prev[`builtUpArea`] = info?.builtUpArea || "";
        prev[`requirementForfloors`] =
          JSON.stringify(info?.requirementForfloors) || "";
        prev[`staircaseType`] = info?.staircaseType || "";
        prev[`direction`] = info?.direction || "";
        prev[`setbacksFront`] = info?.setbacks?.front || "";
        prev[`setbacksBack`] = info?.setbacks?.back|| "";
        prev[`setbacksRight`] = info?.setbacks?.right|| "";
        prev[`setbacksLeft`] = info?.setbacks?.left || "";


        return prev;
      });

      if(info?.direction)
     { setSDirectionCheckedList([info?.direction]);}
      if(info?.staircaseType)
     { setStairCaseCheckedList([info?.staircaseType]);}

      for (let i = 0; i < info?.requirementForfloors?.length; i++) {
        setFloorFilterData(info?.requirementForfloors[i], i);
      }
    }
  }, [state.trelloCardInfo?.cardInfo]);

  useEffect(() => {
    console.log("stairCaseCheckedList", stairCaseCheckedList?.length);
    if (stairCaseCheckedList?.length == 0) {
      console.log("no starecase");
      setFormData((prev) => {
        prev = JSON.parse(JSON.stringify(prev));
        prev[`staircaseType`] = "";
        return prev;
      });
      //  setStairCaseCheckedList('');
    } else {
      console.log("inside Starecaase", stairCaseCheckedList);
      setFormData((prev) => {
        prev = JSON.parse(JSON.stringify(prev));
        prev[`staircaseType`] = JSON.stringify(stairCaseCheckedList);
        return prev;
      });
    }
  }, [stairCaseCheckedList]);
  useEffect(() => {
    if (directionCheckedList?.length == 0) {
      console.log("no directionCheckedList");
      setFormData((prev) => {
        prev = JSON.parse(JSON.stringify(prev));
        prev[`direction`] = "";
        return prev;
      });
      //  setSDirectionCheckedList('');
    } else {
      setFormData((prev) => {
        prev = JSON.parse(JSON.stringify(prev));
        prev[`direction`] = JSON.stringify(directionCheckedList);
        return prev;
      });
    }
  }, [directionCheckedList]);

  return (
    <>
      <FHeading>
        Filters{" "}
        <ActionDiv>
          <span
            style={{
              color: "#7a7ae6",
              fontSize: "16px",
              cursor: "pointer",
              padding: "0 5px",
            }}
            onClick={() => {
              setGroundFloor({});
              setFirstFloor({});
              setsecondFloor({});
              setFormData({});
              setGroundCheckedList([]);
              setFirstCheckedList([]);
              setSecondCheckedList([]);
              setStairCaseCheckedList("");
              setSDirectionCheckedList("");
              props.setFilterClear(1);
            }}
          >
            Clear
          </span>
          <span
            style={{
              color: "#7a7ae6",
              fontSize: "16px",
              cursor: "pointer",
              padding: "0 5px",
            }}
            onClick={() => {
              props.setCurrent({ page: 1, size: 10 });
              props.setSearchData(true);
              if (!formData?.projectID) {
                Object.entries(formData).map(([key, value], formDataIndex) => {
                  if (key == "projectID" && !value) {
                    params.delete("projectID");
                    history.replace({ search: params.toString() });
                  }
                });
              }
            }}
          >
            Search
          </span>
        </ActionDiv>
      </FHeading>
      <FDiv>
        <Accordion allowToggle defaultIndex={[0]}>
          <AccordionItem>
            <h2>
              <AccordionButton
                style={{ fontWeight: "500", color: " var( --primary-green)" }}
                onClick={(e) => {
                  setIsToolTIp(!isToolTIp);
                }}
              >
                <Box flex="1" textAlign="left">
                  Primary
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <FItem>
                <FormControl as="fieldset"style={{display:"flex", flexWrap: "wrap",justifyContent: 'space-between',paddingBottom:"5px"}}>
                  <FormLabel className="f-lable" style={{width:"28%"}}>Project ID</FormLabel>
                  <Input
                   style={{width:"65%"}}

                    allowClear
                    placeholder="Enter Project Id..."
                    name="projectID"
                    value={formData?.projectID}
                    onChange={(event) => handleSearchForm(event)}
                  />
                </FormControl>
              </FItem>
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">Preview Available</FormLabel>
                  <RadioGroup
                  value={formData?.preview ? formData?.preview : ""}
                    onChange={(e) => {
                      handleSearchForm(
                        {
                          target: { name: "preview", value: e },
                        } );
                    }}
                  >
                      <span className="f-check-group">

                  {PreviewType.map((data, index) => {
                        return (
                          <span className="f-radio-button" key={index}>
                            <Radio value={data.value}>{data.label}</Radio>
                          </span>
                        );
                      })}
                      </span>
                  </RadioGroup>
                </FormControl>
              </FItem>
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">
                    Width <span style={{ fontStyle: "italic" }}>(in FT)</span>
                  </FormLabel>
                  <span className="f-sub-group">
                    <FormControl as="fieldset" className="f-sub-input-group">
                      <span className="f-inline-field">
                        {/* <FormLabel className="f-lable">min</FormLabel> */}
                        <Input
                          allowClear
                          placeholder="min Width..."
                          name="width"
                          value={formData?.width}
                          onChange={(event) => handleSearchForm(event)}
                        />
                      </span>
                    </FormControl>
                    <span style={{fontWeight:'800'}}>-</span>
                    <FormControl as="fieldset" className="f-sub-input-group">
                      <span className="f-inline-field">
                        {/* <FormLabel
                          className="f-lable"
                          style={{ paddingLeft: "5px" }}
                        >
                          max
                        </FormLabel> */}
                        <Input
                          allowClear
                          placeholder="max Width..."
                          name="maxwidth"
                          value={formData?.maxwidth}
                          onChange={(event) => handleSearchForm(event)}
                        />
                      </span>
                    </FormControl>
                  </span>
                </FormControl>
              </FItem>
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">
                    Depth <span style={{ fontStyle: "italic" }}>(in FT)</span>
                  </FormLabel>
                  <span className="f-sub-group">
                    <FormControl as="fieldset" className="f-sub-input-group">
                      <span className="f-inline-field">
                        {/* <FormLabel className="f-lable">min</FormLabel> */}
                        <Input
                          allowClear
                          placeholder="min Depth..."
                          name="depth"
                          value={formData?.depth}
                          onChange={(event) => handleSearchForm(event)}
                        />
                      </span>
                    </FormControl>
                    <span style={{fontWeight:'800'}}>-</span>
                    <FormControl as="fieldset" className="f-sub-input-group">
                      <span className="f-inline-field">
                        {/* <FormLabel
                          className="f-lable"
                          style={{ paddingLeft: "5px" }}
                        >
                          max
                        </FormLabel> */}
                        <Input
                          allowClear
                          placeholder="max Depth..."
                          name="maxdepth"
                          value={formData?.maxdepth}
                          onChange={(event) => handleSearchForm(event)}
                        />
                      </span>
                    </FormControl>
                  </span>
                </FormControl>
              </FItem>

              <FItem>
                
                  <FormControl as="fieldset" >
                    <FormLabel className="f-lable">Floors</FormLabel>
                    <Input
                      allowClear
                      placeholder="Enter Floors..."
                      name="floors"
                      value={formData?.floors}
                      onChange={(event) => handleSearchForm(event)}
                    />
                  </FormControl>
                {/* </span> */}
              </FItem>

              <FItem>
                <FormControl as="fieldset" className="p-check-group">
                  <FormLabel className="f-lable">Stair Case Type</FormLabel>
                  <Checkbox.Group
                    options={stareCaseType}
                    value={stairCaseCheckedList}
                    onChange={(e) => {
                      setStairCaseCheckedList(e);

                      console.log(e);
                    }}
                  />{" "}
                </FormControl>
              </FItem>
              <FItem>
                <FormControl as="fieldset" className="p-check-group">
                  <FormLabel className="f-lable">Direction</FormLabel>
                  <Checkbox.Group
                    options={directions}
                    value={directionCheckedList}
                    onChange={(e) => {
                      setSDirectionCheckedList(e);

                      console.log(e);
                    }}
                  />{" "}
                </FormControl>
              </FItem>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Accordion allowToggle defaultIndex={[0]}>
          <AccordionItem>
            <h2>
              <AccordionButton style={{ fontWeight: "500", color: " var( --primary-green)" }}>
                <Box flex="1" textAlign="left">
                  Ground Floor
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">Master Bedrooms</FormLabel>
                  <Input
                    allowClear
                    value={groundFloor?.masterBedrooms}
                    placeholder="Enter NO of master Bedrooms..."
                    name="masterBedrooms"
                    onChange={(event) => handleSearchForm(event, 0)}
                  />
                </FormControl>
              </FItem>
            

          
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">Common Area Type</FormLabel>
                  <RadioGroup
                    value={
                      groundFloor?.commonAreaType
                        ? groundFloor?.commonAreaType
                        : ""
                    }
                    onChange={(e) => {
                      handleSearchForm(
                        {
                          target: { name: "commonAreaType", value: e },
                        },
                        0
                      );
                    }}
                  >
                    <span>
                      {commonAreaType.map((data, index) => {
                        return (
                          <span className="f-radio-button" key={index}>
                            <Radio value={data.value}>{data.label}</Radio>
                          </span>
                        );
                      })}
                    </span>
                  </RadioGroup>
                </FormControl>
              </FItem>
            
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Accordion allowToggle defaultIndex={[0]}>
          <AccordionItem>
            <h2>
              <AccordionButton style={{ fontWeight: "500", color: " var( --primary-green)" }}>
                <Box flex="1" textAlign="left">
                  1st Floor{" "}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">Master Bedrooms</FormLabel>
                  <Input
                    allowClear
                    value={firstFloor?.masterBedrooms}
                    placeholder="Enter NO of master Bedrooms..."
                    name="masterBedrooms"
                    onChange={(event) => handleSearchForm(event, 1)}
                  />
                </FormControl>
              </FItem>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </FDiv>
    </>
  );
}
