import {
  Button,
  CloseButton,
  Input,
  ModalBody,
  ModalHeader,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import toast from "react-hot-toast";
import moment from "moment";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  FormErrorIcon,
} from "@chakra-ui/form-control";
import { Select, Card, Spin, Table, Radio, Tag } from "antd";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators } from "../../../Redux";
import { API } from "../../../Services";

export default function SendToPartner(props) {
  const state = useSelector((state) => state);
  const [data, setData] = useState({});
  const [selectedPartnerId, setSelectedPartnerId] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const dispatch = useDispatch();
  const { Option } = Select;

  const [tableData, setTableData] = useState([]);
  const { partnersList, partnerDetails, partnerLocations } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const [value, setValue] = useState(1);

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const columns = [
    {
      title: <div className="ap-fsm ap-fwmd">Partner name</div>,
      dataIndex: "partnerName",
      key: "partnerName",
      render: (text) => <div className="ap-fsm">{text}</div>,
      width: "180px",
      fixed: "left",
    },
    {
      title: <div className="ap-fsm ap-fwmd">Location</div>,
      dataIndex: "partnerLocation",
      key: "partnerLocation",
      width: "180px",

      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Partner Since</div>,
      dataIndex: "partnerSince",
      key: "partnerSince",
      width: "250px",

      render: (text) => (
        <div className="ap-fsm">
          {" "}
          {moment(text?.partnerSince).format("MMM D, YYYY [at] h:mm A z")}
        </div>
      ),
    },
    {
      title: "Select",
      key: "action",
      //width:"550px",

      render: (text, record) => (
        <Radio.Group
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          name="radiogroup"
          value={selectedPartnerId}
          onChange={() => {
            console.log(text.action);
            setSelectedPartnerId(text.action);
          }}
        >
          <Radio name="radio" value={text.action}></Radio>
        </Radio.Group>
      ),
      width: "50px",
      fixed: "right",
    },
  ];

  useEffect(() => {
    if (state.partnersList?.customers.length > 0) {
      setTableData((prev) => {
        prev = [];
        state.partnersList.customers.map((project, projectIndex) => {
          prev.push({
            partnerName: project.firstName + " " + project.lastName,
            partnerLocation: project.city,
            partnerSince: project.created_at,
            action: project.id,
          });
        });
        return prev;
      });
    } else {
      setTableData([]);
    }
  }, [state]);

  const setFormData = (event) => {
    setData((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      if (event.target.name === "date") {
        prev[`from`] = event.target.value[0];
        prev[`to`] = event.target.value[1];
        prev.moment = event.target.moment;
      } else {
        prev[`${event.target.name}`] = event.target.value;
      }
      return prev;
    });
  };
  useEffect(() => {
    partnersList();
    partnerLocations();
  }, []);

  const SendRequest = (inputdata) => {
    console.log(inputdata);
    let data = {
      partner_id: selectedPartnerId,
      comment: inputdata.message,
      user_id: props.customerId,
    };
    API.sendToPartner(data)
      .then((res) => {
        // setLoad((prev)=>!prev)
        toast.success(res.data.message, {
          position: "top-right",
        });

        partnerDetails(props.clientID);
        props.onClose();
      })
      .catch((err) => {
        // setLoad((prev)=>!prev)
        toast.error(err.message, {
          position: "top-right",
        });

        props.onClose();
      });
  };
  return (
    <div>
      {" "}
      <ModalHeader style={{ justifyContent: "space-between", display: "flex" }}>
        Send To Partner
        <div>
          <CloseButton onClick={props.onClose} />
        </div>
      </ModalHeader>
      <ModalBody>
        <FormLabel htmlFor="text-input">Select City</FormLabel>

        <Select
          showSearch
          placeholder="Select City"
          size="large"
          loading={state.partnerLocations?.loading}
          labelInValue
          style={{ width: "100%" }}
          onChange={(e) => {
            setSelectedCity(e.value);
            partnersList(`city=${e.value}`);
            console.log("partnerId", e.value);
          }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {state.partnerLocations?.cities?.cities.map(
            (customer, customerIndex) => (
              <Option value={customer?.name} key={customerIndex}>
                {customer?.name}
              </Option>
            )
          )}
        </Select>
        {selectedCity && (
          <Formik
            initialValues={{
              message: "",
              partnerId: "",
            }}
            onSubmit={(values) => SendRequest(values)}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <Field name="name">
                  {({ field, form }) => (
                    <>
                      <FormControl>
                        <FormLabel
                          htmlFor="text-input"
                          style={{ paddingTop: "10px" }}
                        >
                          Select Partner
                        </FormLabel>
                        
                        {!state.partnersList.loading ? (
                          <Table
                            columns={columns}
                            dataSource={tableData}
                            scroll={{
                              y: 250,
                            }}
                            size="small"
                            pagination={false}
                          />
                        ) : (
                          <div
                            className="ap-flex ap-w100 ap-flex-center-center"
                            style={{ height: "300px" }}
                          >
                            <Spin tip="Fetching Records....." />
                          </div>
                        )}
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>

                      <FormControl>
                        <FormLabel
                          htmlFor="text-input"
                          style={{ paddingTop: "10px" }}
                        >
                          Message
                        </FormLabel>
                        <Input
                          type="textarea"
                          id="message"
                          name="message"
                          placeholder="Message..."
                          onChange={(e) => {
                            setFieldValue("message", e.target.value);
                          }}
                          required
                        />
                      </FormControl>
                    </>
                  )}
                </Field>
                <Button
                  mt={4}
                  className="submit_right1"
                  isLoading={isSubmitting}
                  type="submit"
                >
                  Send
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </ModalBody>
    </div>
  );
}
