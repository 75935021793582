import { BaseUrl } from './Networks';
import Swal from 'sweetalert2/dist/sweetalert2.js'
const axios = require('axios');

// For Post Api Calls And Put
export const HttpCallPost = async (method, type, token, body) => {
  return new Promise(async function (resolve, reject) {
    const url = BaseUrl + method;

    axios({
      method: type,
      url: url,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        Accept: 'application/x-www-form-urlencoded',
        'x-api-key': `${token}`
      },
      data: body
    }).then(response => {
        if(response.status === 200){
          return resolve(response);
        }
        return resolve(response);
    })
      .catch(err => {
        return reject(err);
      });
  });
};

export const HttpCallPut = async (method, type, token, body) => {
  return new Promise(async function (resolve, reject) {
    const url = BaseUrl + method;

    axios({
      method: type,
      url: url,
      headers: {
        'content-type': 'application/json',
          Accept: 'application/json',
        'x-api-key': `${token}`
      },
      data: body
    }).then(response => {
        if(response.status === 200){
          return resolve(response);
        }
        return resolve(response);
    })
      .catch(err => {
        return reject(err);
      });
  });
};

//For Get Api Calls
export const HttpCallGet = async (method, type, token) => {
    return new Promise(async function (resolve, reject) {
     const url = BaseUrl + method;

    axios({
      method: type,
      url: url,
      headers: {
        'content-type': 'application/json',
          Accept: 'application/json',
        'x-api-key': `${token}`
      },
    }).then(response => {
      if(response.status === 403){
        return resolve(response);
      }
        return resolve(response);
    })
      .catch(err => {
        return err;
      });
  });
};

//For Api Error Handling Globaly
export const handleError = (errResponse) =>{
  if(errResponse.response?.status === 403 ){
      localStorage.clear()
    return Swal.fire({
      position: 'center',
      type: 'error',
      title: 'Invalid User.Please Login Again...',
    }).then((ok)=>{
      window.location.assign('/login')
    })
  }
  else if(errResponse.response?.status === 404){
    Swal.fire({
      position: 'center',
      type: 'error',
      title: errResponse.response.data,
    })
  }
  else if(errResponse.response?.status === 500){
    Swal.fire({
      position: 'center',
      type: 'error',
      title: errResponse.response.data,
    })
   }
   else if(errResponse.response?.status === 400){
    Swal.fire({
      position: 'center',
      type: 'error',
      title: errResponse.response.data,
    })
   }
}