import React, { useEffect, useRef, useState } from "react";
import "./TrelloList.scss";
import { Button } from "@chakra-ui/react";
import { Card, Select, Table, Spin, Input, Space, DatePicker } from "antd";
import moment from "moment";
import { Icons } from "../../Utils";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../Redux";
import { API } from "../../Services";
import { useHistory, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { SearchOutlined } from "@material-ui/icons";

const TrelloList = (props) => {
  const state = useSelector((state) => state);
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const dispatch = useDispatch();
  const { trelloBoards, trelloSearchCardList, admins, resetState } =
    bindActionCreators(actionCreators, dispatch);
  const { Option } = Select;
  const [current, setCurrent] = useState({
    page: 1,
    size: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [formData, setFormData] = useState({
    board: { value: "", label: "Please select board name" },
    list: { value: "", label: "Please select list name" },
    member: { value: "", label: "Please select member name" },
  });
  const [boardData, setBoardData] = useState("");
  const [memberData, setmemberData] = useState("");
  const [trelloListIds, setTrelloListIds] = useState([]);
  const params = new URLSearchParams(window.location.search);
  const { Search } = Input;
  const [searchCategory, setSearchCategory] = useState("project");
  const [sortby, setSortBy] = useState("dueDate");

  const TodayDate = new Date();
const handleResetClick = () => {
  resetState();
};
  const handleMoveToTrelloDetails = (id) => {
    localStorage.setItem("istrelloList", true);

    let projectID = state.trelloSearchCardList.data
      .filter((data) => id == data.projectID)
      .map(({ projectId }) => ({ projectId }));

    console.log(projectID);
    localStorage.setItem("projectId", projectID[0].projectId);
    localStorage.setItem("orderCode", id);

    history.push("/home/ap/trello/details/" + id);
  };
  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Space>
            <DatePicker
              format={"MMM D, YYYY"}
              defaultValue={searchText}
              value={selectedKeys[0]}
              allowClear={false}
              onChange={(e) => {
                setSelectedKeys([e]);
              }}
              style={{ padding: "5px 26px" }}
            />
          </Space>
          <br />

          <Space style={{ padding: "10px 0" }}>
            <Button
              type="primary"
              onClick={() => {
                handleSearch(selectedKeys, confirm, dataIndex);
                setCurrent({
                  page: 1,
                  size: 10,
                });
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: "90px", height: "30px" }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: "90px", height: "30px" }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        // record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.format("MMM D, YYYY").toLowerCase()),

      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          // setTimeout(() => this.searchInput.select());
        }
      },
    };
  }
  function handleSearch(selectedKeys, confirm, dataIndex) {
    // confirm();
    setSearchText(selectedKeys[0]);
    // setSearchedColumn(dataIndex);
    let dueDate = moment(selectedKeys[0]?._d).format("yyyy-MM-DD ");
    console.log(dueDate);
    trelloSearchCardList(
      params.get(`memberId`) || "",
      params.get(`bardId`) || "",
      params.get(`listId`) || "",
      "",
      "",
      current?.page,
      dueDate,
      sortby,
      sortby == "dueDate" ? "ASC" : "DESC"
    );
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText("");
    setSearchText("");
    trelloSearchCardList(
      params.get(`memberId`) || "",
      params.get(`bardId`) || "",
      params.get(`listId`) || "",
      "",
      "",
      current?.page,
      "",
      sortby,
      sortby == "dueDate" ? "ASC" : "DESC"
    );
  }

  const columns = [
    {
      title: <div className="ap-fsm ap-fwmd">Title</div>,
      dataIndex: "title",
      key: "title",
      render: (text) => <div className="ap-fsm">{text}</div>,
      width: "200px",
    },
    {
      title: <div className="ap-fsm ap-fwmd">Last update</div>,
      dataIndex: "lastUpdate",
      key: "lastUpdate",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">List name</div>,
      dataIndex: "listName",
      key: "listName",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Board name</div>,
      dataIndex: "boardName",
      key: "boardName",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Created By</div>,
      dataIndex: "createdBy",
      key: "createdBy",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Assigned to</div>,
      dataIndex: "assignedTo",
      key: "assignedTo",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Due date</div>,
      dataIndex: "dueDate",
      key: "dueDate",
      ...getColumnSearchProps("dueDate"),
      render: (text) => (
        <div
          className="ap-fsm"
          style={{
            background: TodayDate > new Date(text) ? "#ff0000c7" : "",
            padding: "0 5px",
          }}
        >
          {" "}
          {extractDate(text)}
        </div>
      ),
    },
    {
      title: <div className="ap-fsm ap-fwmd">Action</div>,
      dataIndex: "id",
      key: "id",
      render: (text) => (
        <div className="ap-fsm">
          <Button
            size="sm"
            className="submit_right1"
            onClick={() => {
              handleMoveToTrelloDetails(text);
                            handleResetClick();

            }}
          >
            Show Details
          </Button>
        </div>
      ),
    },
  ].filter((item) => !item.hidden);

  const handleFormData = (data) => {
    setFormData((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      if (
        data.target.name === "board" &&
        prev?.board.value !== data.target.value?.value
      ) {
        prev.list = { value: "", label: "Please select list name" };
        prev.member = { value: "", label: "Please select member name" };
      }
      prev[`${data.target.name}`] = data.target.value;
      return prev;
    });
  };

  useEffect(() => {
    if (state.trelloSearchCardList?.data?.length > 0) {
      setTableData((prev) => {
        prev = [];
        state.trelloSearchCardList.data.map((card, cardIndex) => {
          prev.push({
            key: cardIndex,
            title: card?.projectName,
            lastUpdate: extractDate(card?.updatedAt),
            listName: card?.list?.name,
            boardName: card?.board?.name,
            dueDate: card?.dueDate,
            id: card?.projectID,
            assignedTo: card?.assignedTo?.name||'NA',
            createdBy: card?.createdBy?.name,
          });
        });
        return prev;
      });
    } else {
      setTableData([]);
    }
    if (state?.trelloBoards?.boards !== "") {
      setBoardData(state?.trelloBoards?.boards);
    }
    if (state?.admins?.admins !== "") {
      setmemberData(state?.admins?.admins);
    }
  }, [state]);

  useEffect(() => {
    if (boardData !== "" && params.get(`bardId`)) {
      console.log(params.get(`bardId`));
      let SelectedBoard = state?.trelloBoards?.boards
        .filter((data) => params.get(`bardId`) == data.board_id)
        .map(({ board_id, board_name }) => ({ board_id, board_name }));

      console.log(SelectedBoard);
      setFormData((prev) => {
        prev = JSON.parse(JSON.stringify(prev));
        prev.list = { value: "", label: "Please select list name" };
        prev.member = { value: "", label: "Please select member name" };
        prev[`board`] = {
          value: SelectedBoard[0]?.board_id,
          label: SelectedBoard[0]?.board_name,
          key: SelectedBoard[0]?.board_id,
        };
        return prev;
      });
    }
  }, [boardData]);

  useEffect(() => {
    if (memberData !== "" && params.get(`memberId`)) {
      console.log(params.get(`memberId`));
      let SelectedBoard = state?.admins?.admins
        .filter((data) => params.get(`memberId`) == data.admin_id)
        .map(({ admin_id, admin_firstname, admin_lastname }) => ({
          admin_id,
          admin_firstname,
          admin_lastname,
        }));

      console.log(SelectedBoard);
      setFormData((prev) => {
        prev = JSON.parse(JSON.stringify(prev));
        console.log(prev);
        prev[`member`] = {
          value: SelectedBoard[0]?.admin_id,
          label:
            SelectedBoard[0]?.admin_firstname +
            " " +
            SelectedBoard[0]?.admin_lastname,
          key: SelectedBoard[0]?.admin_id,
        };
        return prev;
      });
    }
  }, [memberData]);
  useEffect(() => {
    if (trelloListIds !== "" && params.get(`listId`)) {
      console.log(params.get(`listId`));
      let SelectedBoard = trelloListIds
        .filter((data) => params.get(`listId`) == data.ID)
        .map(({ ID, trelloListName }) => ({
          ID,
          trelloListName,
        }));

      console.log(SelectedBoard);
      setFormData((prev) => {
        prev = JSON.parse(JSON.stringify(prev));
        prev[`list`] = {
          value: SelectedBoard[0]?.ID,
          label: SelectedBoard[0]?.trelloListName,
          key: SelectedBoard[0]?.ID,
        };
        return prev;
      });
    }
  }, [trelloListIds]);

  const extractDate = (date) => {
    // let dateObj = new Date(date);
    // let month = dateObj.getUTCMonth() + 1; //months from 1-12
    // let day = dateObj.getUTCDate();
    // let year = dateObj.getUTCFullYear();
    // let time = dateObj.toLocaleTimeString();
    // return `${day}/${month}/${year} ${time}`;
    return moment(date).format("MMM D, YYYY [at] h:mm A z");
  };

  const searchData = () => {
    localStorage.setItem("trelloListPage", 1);

    console.log(formData?.member?.value);
    console.log(formData?.list?.value);
    console.log(formData?.board?.value);

    trelloSearchCardList(
      formData?.member?.value || "",
      formData?.board?.value || "",
      formData?.list?.value || "",
      "",
      "",
      current?.page,
      " ",
      sortby,
      sortby == "dueDate" ? "ASC" : "DESC"
    );

    if (formData?.board?.value !== "") {
      params.set("bardId", formData?.board?.value);
      localStorage.setItem("bardId", formData?.board?.value);
    }
    if (formData?.board?.value !== "") {
      params.set("listId", formData?.list?.value);
      localStorage.setItem("listId", formData?.list?.value);
    } else {
      params.delete("listId");
    }
    if (formData?.board?.value !== "") {
      params.set("memberId", formData?.member?.value);
      localStorage.setItem("memberId", formData?.member?.value);
    } else {
      params.delete("memberId");
    }
    history.push({ search: params.toString() });
    //history.push(null,'', location.origin + { search: params.toString() });
  };

  useEffect(() => {
    trelloBoards();
    admins();
    if (
      params.get(`listId`) ||
      params.get(`bardId`) ||
      params.get(`memberId`)
    ) {
      let page;
      if (localStorage.getItem("trelloListPage")) {
        page = localStorage.getItem("trelloListPage");
        console.log(page);
        setCurrent({
          page: page,
          size: 10,
        });
      } else {
        page = current?.page;
      }

      trelloSearchCardList(
        params.get(`memberId`) || "",
        params.get(`bardId`) || "",
        params.get(`listId`) || "",
        "",
        "",
        page,
        "",
        sortby,
        sortby == "dueDate" ? "ASC" : "DESC"
      );
    }

    // API.trelloMe()
    //   .then((res) => {
    //     localStorage.setItem(
    //       "trelloMeId",
    //       JSON.parse(JSON.stringify(res))?.data?.id
    //     );
    //   })
    //   .catch((err) => {});
  }, []);

  useEffect(() => {
    if (formData?.board.value !== "") {
      state.trelloBoards.boards.map((board) => {
        if (board?.board_id === formData?.board?.value) {
          setTrelloListIds(board?.board_list);
        }
      });
    } else if (formData?.board.value === "") {
      setTrelloListIds([]);
    }
  }, [formData]);

  return (
    <div className="ap-flex ap-w100 ap-flex-center-center trello-list">
      {/* Head */}
      <div className="ap-flex ap-w100 ap-flex-space-bw">
        <div className="ap-flex ap-flex-center-center ap-fmd ap-fwmd">
          Design PMS
        </div>
      </div>
      <br />
      <div className="ap-flex ap-w100 ap-flex-space-bw">
        <div className="ap-flex ap-flex-center-center ap-fmd ap-fwmd">
          <br />

          <Input.Group compact>
            <Select
              defaultValue="project"
              onChange={(e) => {
                setSearchCategory(e);
              }}
            >
              <Option value="project">Project</Option>
              <Option value="order">Order</Option>
            </Select>
            <Search
              placeholder="input search text"
              allowClear
              onSearch={(e) => {
                trelloSearchCardList(
                  "",
                  "",
                  "",
                  searchCategory,
                  e,
                  current?.page,
                  "",
                  sortby,
                  sortby == "dueDate" ? "ASC" : "DESC"
                );
                console.log(e, searchCategory);
                localStorage.removeItem("trelloListPage");
              }}
              style={{
                width: 250,
              }}
            />
            {/* <Input
        style={{
          width: '50%',
        }}
        defaultValue="input content"
      /> */}
            {/* <InputNumber /> */}
          </Input.Group>
        </div>
        <div style={{ padding: "0 15px" }}>
          <span className="ap-flex ap-fsm ap-w100">Sort By</span>
          <Select
            defaultValue="dueDate"
            onChange={(e) => {
              console.log(current?.page);
              setSortBy(e);
              trelloSearchCardList(
                params.get(`memberId`) || "",
                params.get(`bardId`) || "",
                params.get(`listId`) || "",
                "",
                "",
                current?.page,
                "",
                e,
                e == "dueDate" ? "ASC" : "DESC"
              );
            }}
          >
            <Option value="updatedAt">Last Modified</Option>
            <Option value="dueDate">Due Date</Option>
          </Select>
        </div>
      </div>
      {/* Head */}

      {/* Filter */}
      <Card size="small" style={{ width: "100%", marginTop: "10px" }}>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            searchData();
          }}
        >
          <div className={`ap-flex ap-flex-space-bw ap-w100`}>
            <div
              className="ap-flex ap-flex-center-center ap-w33"
              style={{ padding: "0 5px 0px 0" }}
            >
              <span className="ap-flex ap-fsm ap-fwmd ap-w100">Board name</span>
              <Select
                showSearch
                size="middle"
                value={formData?.board}
                defaultValue={formData?.board}
                labelInValue
                placeholder="Please select board name"
                onChange={(data) => {
                  handleFormData({ target: { value: data, name: "board" } });
                }}
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={""}>Please select board name</Option>
                {state.trelloBoards.boards.map((board) => (
                  <Option value={board?.board_id} key={board?.board_id}>
                    {board?.board_name}{" "}
                  </Option>
                ))}
              </Select>
            </div>
            <div
              className="ap-flex ap-flex-center-center ap-w33"
              style={{ padding: "0 5px 0px 0" }}
            >
              <span className="ap-flex ap-fsm ap-fwmd ap-w100">List name</span>
              <Select
                showSearch
                size="middle"
                value={formData?.list}
                defaultValue={formData?.list}
                labelInValue
                placeholder="Please select list name"
                onChange={(data) => {
                  handleFormData({ target: { value: data, name: "list" } });
                  console.log(data);
                }}
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                disabled={formData?.board?.value === ""}
                loading={state.trelloBoards?.loading}
              >
                <Option value={""}>Please select list name</Option>
                {trelloListIds.map((list) => (
                  <Option value={list?.ID} key={list?.ID}>
                    {list?.trelloListName}
                  </Option>
                ))}
              </Select>
            </div>
            <div
              className="ap-flex ap-flex-center-center ap-w33"
              style={{ padding: "0 5px 0px 0" }}
            >
              <span className="ap-flex ap-fsm ap-fwmd ap-w100">
                Member name
              </span>
              <Select
                showSearch
                size="middle"
                value={formData?.member}
                defaultValue={formData?.member}
                labelInValue
                placeholder="Please select Member name"
                onChange={(data) => {
                  handleFormData({ target: { value: data, name: "member" } });
                  console.log(data);
                }}
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                disabled={
                  formData?.board?.value === "" || formData?.board?.board === ""
                }
                loading={state.trelloBoards?.loading}
              >
                {state.admins.admins.map((member) => (
                  <Option value={member?.admin_id} key={member?.admin_id}>
                    {member?.admin_firstname + " " + member?.admin_lastname}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="ap-flex ap-w33"></div>
          </div>
          <div className={`ap-flex ap-w100`} style={{ marginTop: "10px" }}>
            <div className={`ap-flex ap-w100`}>
              <div className="ap-flex ap-flex-center-center">
                <Button
                  size="sm"
                  leftIcon={<Icons.Search />}
                  className="submit_right1"
                  type="submit"
                  isDisabled={formData?.board?.value === ""}
                >
                  Search
                </Button>
              </div>
              <div
                className="ap-flex ap-flex-center-center"
                style={{ marginLeft: "10px" }}
              >
                <Button
                  size="sm"
                  type="button"
                  leftIcon={<Icons.Sweep />}
                  className="submit_right1"
                  isDisabled={formData?.board?.value === ""}
                  onClick={() => {
                    setFormData({
                      board: { value: "", label: "Please select board name" },
                      list: { value: "", label: "Please select list name" },
                      member: { value: "", label: "Please select member name" },
                    });
                    params.delete("listId");
                    params.delete("bardId");
                    params.delete("memberId");

                    localStorage.removeItem("trelloListPage");
                    localStorage.removeItem("listId");
                    localStorage.removeItem("bardId");
                    localStorage.removeItem("memberId");
                    history.push({ search: params.toString() });
                    setTableData([]);
                    window.location.reload();
                  }}
                >
                  Clear
                </Button>
              </div>
            </div>
            <div
              className="ap-flex ap-flex-end"
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "right",
                width: "30%",
                padding: "0 15px",
              }}
            >
              <div className="mr-1 submit_right1" style={{ color: "gray" }}>
                Total Records:{" "}
                {state.trelloSearchCardList.totalRecords
                  ? state.trelloSearchCardList.totalRecords
                  : 0}
              </div>
            </div>
          </div>
        </form>
      </Card>
      {/* Filter */}

      {/* List */}
      <Card size="small" style={{ width: "100%", marginTop: "10px" }}>
        {!state.trelloSearchCardList.loading ? (
          <Table
            columns={columns}
            dataSource={tableData}
            scroll={{ x: 1000 }}
            size="small"
            pagination={{
              //defaultCurrent: 2,
              current: current.page,
              pageSize: current.size,
              position: ["bottomRight"],
              total: state.trelloSearchCardList.totalRecords,
              onChange: (data, size) => {
                console.log(data, size);
                setCurrent({ page: data, size: size });
                localStorage.setItem("trelloListPage", data);
                trelloSearchCardList(
                  params.get(`memberId`) || "",
                  params.get(`bardId`) || "",
                  params.get(`listId`) || "",
                  "",
                  "",
                  data,
                  "",
                  sortby,
                  sortby == "dueDate" ? "ASC" : "DESC"
                );
              },
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items / page-${current.page}`,
            }}
          />
        ) : (
          <div
            className="ap-flex ap-w100 ap-flex-center-center"
            style={{ height: "400px" }}
          >
            <Spin tip="Fetching Records....." />
          </div>
        )}
      </Card>
    </div>
  );
};

export default TrelloList;
