import React, { useEffect, useState } from "react";
import {
  ActionDiv,
  FDiv,
  FHeading,
  FItem,
} from "./interioriDesignListingStyle";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  HStack,
  Radio,
} from "@chakra-ui/react";
import { Input, Select } from "antd";

export default function InterioriDesignFilter(props) {
  const [formData, setFormData] = useState({ imagetype: "any" });
 const widthDepthOptions = [
   { value: 1, label: "1" },
   { value: 2, label: "2" },
   { value: 3, label: "3" },
   { value: 4, label: "4" },
   { value: 5, label: "5" },
   { value: 6, label: "6" },
   { value: 7, label: "7" },
   { value: 8, label: "8" },
   { value: 9, label: "9" },
   { value: 10, label: "10" },
   { value: 11, label: "11" },
   { value: 12, label: "12" },
   { value: 13, label: "13" },
   { value: 14, label: "14" },
   { value: 15, label: "15" },
   { value: 16, label: "16" },
   { value: 17, label: "17" },
   { value: 18, label: "18" },
   { value: 19, label: "19" },
   { value: 20, label: "20" },
   { value: 21, label: "21" },
   { value: 22, label: "22" },
   { value: 23, label: "23" },
   { value: 24, label: "24" },
   { value: 25, label: "25" },
   { value: 26, label: "26" },
   { value: 27, label: "27" },
   { value: 28, label: "28" },
   { value: 29, label: "29" },
   { value: 30, label: "30" },
   { value: "XX", label: "XX" },
 ];

   useEffect(() => {
     if (formData.category)
       props.getSubCategoriesList(`category=${formData.category}`);
   }, [formData.category]);
  useEffect(() => {
    props.setFilterData(formData);
  }, [formData]);
  const { Option } = Select;

  const handleSearchForm = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <>
      <FHeading>
        Filters
        <ActionDiv>
          <span
            style={{
              color: "#7a7ae6",
              fontSize: "16px",
              cursor: "pointer",
              padding: "0 5px",
            }}
            onClick={() => {
              setFormData({});
              props.setFilterClear(1);
            }}
          >
            Clear
          </span>
          <span
            style={{
              color: "#7a7ae6",
              fontSize: "16px",
              cursor: "pointer",
              padding: "0 5px",
            }}
            onClick={() => {
              const searchQuery = Object.entries(formData)
                .map(([key, value]) => `${key}=${value}`)
                .join("&");

              props.interiorDesignList(
                `limit=50&offset=0&page=1&${searchQuery}`
              );
            }}
          >
            Search
          </span>
        </ActionDiv>
      </FHeading>
      <FDiv>
        <Accordion allowToggle defaultIndex={[0]}>
          <AccordionItem>
            <h2>
              <AccordionButton
                style={{ fontWeight: "500", color: "var(--primary-green)" }}
              >
                <Box flex="1" textAlign="left">
                  Primary Filters
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">Project ID</FormLabel>
                  <Input
                    allowClear
                    value={formData.projectID || ""}
                    placeholder="Enter Project ID..."
                    name="projectID"
                    onChange={handleSearchForm}
                  />
                </FormControl>
              </FItem>
              <FItem>
                <span className="f-check-group">
                  <FormControl as="fieldset" className="f-input-group">
                    <FormLabel className="f-lable">Width</FormLabel>
                    <Select
                      size="medium"
                      labelInValue
                      placeholder="Select Width"
                      style={{ width: "98%" }}
                      value={formData?.width}
                      onChange={(d) =>
                        handleSearchForm({
                          target: { name: "width", value: d.value },
                        })
                      }

                      // filterOption={false}
                    >
                      {widthDepthOptions.map((row, rowIndex) => (
                        <Option value={row.value} key={rowIndex}>
                          {row.label}
                        </Option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl as="fieldset" className="f-input-group">
                    <FormLabel className="f-lable">Depth</FormLabel>
                    <Select
                      size="medium"
                      labelInValue
                      placeholder="Select Depth"
                      style={{ width: "98%" }}
                      value={formData?.depth}
                      onChange={(d) =>
                        handleSearchForm({
                          target: { name: "depth", value: d.value },
                        })
                      }

                      // filterOption={false}
                    >
                      {widthDepthOptions.map((row, rowIndex) => (
                        <Option value={row.value} key={rowIndex}>
                          {row.label}
                        </Option>
                      ))}
                    </Select>
                  </FormControl>
                </span>
              </FItem>
              <FItem>
                <span className="f-check-group">
                  <FormControl as="fieldset" className="f-input-group">
                    <FormLabel className="f-lable">Category</FormLabel>
                    <Select
                      size="medium"
                      labelInValue
                      placeholder="Select Category"
                      style={{ width: "98%" }}
                      value={formData?.category}
                      onChange={(d) =>
                        handleSearchForm({
                          target: { name: "category", value: d.value },
                        })
                      }

                      // filterOption={false}
                    >
                      {props?.categories?.map((row, rowIndex) => (
                        <Option value={row} key={rowIndex}>
                          {row}
                        </Option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl as="fieldset" className="f-input-group">
                    <FormLabel className="f-lable">Subcategory</FormLabel>
                    <Select
                      size="medium"
                      labelInValue
                      placeholder="Select Sub Category"
                      style={{ width: "98%" }}
                      value={formData?.subcategory}
                      disabled={!formData?.category}
                      onChange={(d) =>
                        handleSearchForm({
                          target: { name: "subcategory", value: d.value },
                        })
                      }

                      // filterOption={false}
                    >
                      {props?.subCategories?.map((row, rowIndex) => (
                        <Option value={row} key={rowIndex}>
                          {row}
                        </Option>
                      ))}
                    </Select>
                  </FormControl>
                </span>
              </FItem>
              <FItem>
                <FormControl as="fieldset" className="p-check-group">
                  <FormLabel className="f-lable">Image Type</FormLabel>
                  <RadioGroup
                    name="imagetype"
                    onChange={(e) => {
                      handleSearchForm({
                        target: { name: "imagetype", value: e },
                      });
                    }}
                    value={formData.imagetype}
                  >
                    <span
                      className="f-radio-button"
                      style={{ margin: "0 0.5rem" }}
                    >
                      <Radio value="2D">2D</Radio>
                    </span>
                    <span
                      className="f-radio-button"
                      style={{ margin: "0 0.5rem" }}
                    >
                      <Radio value="3D">3D</Radio>
                    </span>
                    <span
                      className="f-radio-button"
                      style={{ margin: "0 0.5rem" }}
                    >
                      <Radio value="any">Any</Radio>
                    </span>
                  </RadioGroup>
                </FormControl>
              </FItem>
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">Project Type</FormLabel>
                  <RadioGroup
                    name="projectType"
                    onChange={(e) => {
                      handleSearchForm({
                        target: { name: "projectType", value: e },
                      });
                    }}
                  >
                    <span
                      className="f-radio-button"
                      style={{ margin: "0 0.5rem" }}
                    >
                      {" "}
                      <Radio value="RESIDENTIAL">Residential</Radio>
                    </span>
                    <span
                      className="f-radio-button"
                      style={{ margin: "0 0.5rem" }}
                    >
                      <Radio value="COMMERCIAL">Commercial</Radio>
                    </span>
                  </RadioGroup>
                </FormControl>
              </FItem>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </FDiv>
    </>
  );
}
