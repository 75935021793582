import { Button } from "@chakra-ui/button";
import { ModalBody, ModalFooter } from "@chakra-ui/modal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../Redux";
import { API } from "../../Services";

export default function ChatOnWhatsapp(props) {
    const { id } = useParams();
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const [adminDetail,setAdminDetail]=useState(JSON.parse(localStorage.getItem('adminDetails')));

    const {  project,trelloCommentList} = bindActionCreators(
      actionCreators,
      dispatch
    );
    useEffect(() => {

     if(props.projectId)
     {
        project(props.projectId)
     }
    }, [props.projectId])
    

    const goToUrl = () =>{
          window.open(`https://wa.me/${state.project.project.customer.isdCode + state.project.project.customer.phone_number}?text=Hi,Let's connect here for discussion on Designs. For Project ${state.project?.project?.projectCode} Regards,${adminDetail.firstName +' '+adminDetail.lastName
        }`)
   
         let inputdata = `${adminDetail.firstName +' '+adminDetail.lastName
        } initiated conversation with client on Whatsapp`
         let data = { comment: inputdata, type: "T" };

         API.addTrelloComment(id, data)
         .then((res) => {
           console.log(res);
           trelloCommentList(id, 20, 1);
           props.onClose();

         })
         .catch((err) => {
           console.log(err)
           props.onClose();
         });
        } 
  return (
    <div>
      <ModalBody>
        <span style={{fontWeight: "600"}}>
        Are You Sure You Want to chat on Whatsapp ?
        </span>
      </ModalBody>
      <ModalFooter>
        <div style={{padding:"20px 35px"}}>
        <Button
          mr={3}
          className="submit_right1"
          type="submit"
          onClick={() => {
             goToUrl();
          }}
        >
          Yes
        </Button>
        <Button
          colorScheme="red"
          type="submit"
          onClick={() => {
            props.onClose();
        }}
        >
          No
        </Button>
        </div>
      </ModalFooter>
      {/* )} */}
    </div>
  );
}
