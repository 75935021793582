import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/form-control";
import { Select } from "antd";
import toast from "react-hot-toast";
import {
  Button,
  
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "../../Redux";
import { bindActionCreators } from "redux";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { API } from "../../Services";
import { useParams } from "react-router-dom";
import { FIRESTORE_KEY } from "../../chatapp/apis/Networks";


export default function MoveCard(props) {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { Option } = Select;
  const [boardList, setBoardList] = useState("");
  const [QuickLoader, setQuickLoader] = useState(false);
  const [type, setType] = useState("");
  const [trelloList, setTrelloList] = useState("");
  const [selectedBoardId, setSelectedBoardId] = useState("");
  const [selectedListId, setSelectedListId] = useState("");
  const { id } = useParams();
  const { trelloBoards , trelloCardInfo,trelloCommentList } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const MoveCardUpdate = (type) => {
    setType(type||"");
    let data ;
    let DefaultBoardID = FIRESTORE_KEY==="mmh-consumer-testing"?"5fd0cdadb7fde10f581b46fa":"55f7ca76c2edd03d31022b79";
   
    if(type == "ProjectToAssign"){
      data = {
       id:id,
       listID: "36",
       boardID: DefaultBoardID,
 
     };
   }else
    if(type == "SentToClient")
    {
       data = {
        id:id,
        listID: "6",
        boardID: DefaultBoardID,
  
      };
    }else  if(type == "Planning/Changes")
   {  data = {
      id:id,
      listID: "3",
      boardID: DefaultBoardID,

    };}
    else if(type == "PlanningAnalysis"){
       data = {
        id:id,
        listID:FIRESTORE_KEY==="mmh-consumer-testing"?"40":"159",
        boardID: DefaultBoardID,
  
      };
    } 
    else {
       data = {
        id:id,
        listID: selectedListId.value,
        boardID: selectedBoardId.value,
  
      };
    }

    console.log("type",type,"data",data)
    //  setLoad((prev)=>!prev)
    API.trelloCardMove(id, data)
      .then((res) => {
        //  setLoad((prev)=>!prev)
       // trelloList(id);
        trelloCardInfo(id);
        toast.success("Card Moved successfully.", {
          position: "top-right",
        });
        console.log(res);
        trelloCommentList(id, 20, 1);
        setQuickLoader(false);
        setType("");
        props.onClose();
      })
      .catch((err) => {
        // setLoad((prev)=>!prev)
        toast.error("Oops! Card Did'nt Move.", {
          position: "top-right",
        });
        props.onClose();
      });
  };
  useEffect(() => {
    trelloBoards();
    

  }, []);
  useEffect(() => {
    if (state.trelloBoards?.boards !== "") {
      setBoardList(state?.trelloBoards?.boards);
     
    }
    if(state.trelloBoards?.boards !== "" && state.trelloCardInfo?.cardInfo?.board?.ID !== "")
    { setSelectedBoardId({value:state.trelloCardInfo?.cardInfo?.board?.ID,label:state.trelloCardInfo?.cardInfo?.board?.name});
   

    var boardList = state?.trelloBoards?.boards.filter(d => d.board_id == state.trelloCardInfo?.cardInfo?.board?.ID).map((d)=>{return d.board_list})
    setTrelloList(boardList[0]);
    console.log(boardList)
   }
   if(state.trelloCardInfo?.cardInfo?.list?.ID !== "")
   { setSelectedListId({value:state.trelloCardInfo?.cardInfo?.list?.ID,label:state.trelloCardInfo?.cardInfo?.list?.name});
  
  }
   
  }, [state]);
  useEffect(() => {
   
    if (state.trelloBoards?.boards !== "" && selectedBoardId !== "") {
      var boardList = state?.trelloBoards?.boards.filter(d => d.board_id == selectedBoardId.value).map((d)=>{return d.board_list})
      setTrelloList(boardList[0]);
      console.log(boardList[0])
    }
  }, [selectedBoardId]);

  // useEffect(() => {
    
  //   if (selectedBoardId !== "") {
  //     trelloNewList(selectedBoardId);
  //   }
  // }, [selectedBoardId]);
  return (
    <div>
      <div style={{display: "flex",
    justifyContent:"space-between",
    flexFlow: "column",
    padding: "20px 10px"}}>
      <span style={{display: "flex",
    justifyContent:"space-between",
   }}>
      <Button
              disabled={type == ""?false:true}
              mt={4}
              className="submit_right1"
              style={{width: "45%"}}
              isLoading={QuickLoader && type =="SentToClient"}
              onClick={()=>{MoveCardUpdate("SentToClient");setQuickLoader(true)}}
            >
              Move to Sent to Client
            </Button>
            <Button
              disabled={type == ""?false:true}
              mt={4}
              style={{width: "45%"}}
              className="submit_right1 "
              onClick={()=>{MoveCardUpdate("Planning/Changes");setQuickLoader(true)}}
              isLoading={QuickLoader && type == "Planning/Changes"}
              type="submit"
            >
              Move to Planning/Changes Received
            </Button>
            </span>
            <span style={{display: "flex",
    justifyContent:"space-between",
    }}>
            <Button
              disabled={type == ""? false:true}
              mt={4}
              style={{width: "45%"}}
              className="submit_right1"
              onClick={()=>{MoveCardUpdate("PlanningAnalysis");setQuickLoader(true)}}
              isLoading={QuickLoader && type =="PlanningAnalysis"}
              type="submit"
            >
              Move to Planning Analysis
            </Button>
            <Button
              disabled={type == ""?false:true}
              mt={4}
              className="submit_right1"
              style={{width: "45%"}}
              onClick={()=>{MoveCardUpdate("ProjectToAssign");setQuickLoader(true)}}
              isLoading={QuickLoader && type =="ProjectToAssign"}
              type="submit"
            >
              Move to Project to Assign
            </Button>
            </span>
      </div>
      <span style={{display:"flex",justifyContent: "space-between",alignItems: "center"}}>
      <hr  style={{width:"45%"}}/>or <hr style={{width:"45%"}} />
      </span>
      <div style={{display:"flex",justifyContent: "center",alignItems: "center",padding:"20px 10px"}}>
      <Formik
        initialValues={{
          trelloTitle: "",
        }}
        onSubmit={(values) => { props.SetCommentPage(1);
          MoveCardUpdate();}}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form           style={{width: "50%"}}
          >
            <Field name="name">
              {({ field, form }) => (
                <>
                  <FormControl>
                   
                    <FormLabel htmlFor="text-input">Select Board</FormLabel>
                    {boardList && (
                      <Select
                        placeholder="Select Board"
                        size="lg"
                        labelInValue
                        style={{ width: "100%" }}
                        defaultValue={selectedBoardId}
                        onChange={(data) => {
                          console.log(data)
                          setSelectedBoardId(data);
                          setSelectedListId({value:"",label:"Select List ID"});


                        }}
                       
                      >
                        {boardList.map((board, index) => (
                          <Option value={board?.board_id} >
                            {board?.board_name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                  <br />
                  <FormControl>
                    <FormLabel htmlFor="text-input">Select List Item</FormLabel>
                    {boardList && (
                    <>
                      <Select
                        placeholder="Select List ID"
                        size="lg"
                        labelInValue
                        defaultValue={selectedListId}
                        value={selectedListId}
                        onChange={(data) => {
                          console.log(data)

                          setSelectedListId(data);
                        }}
                        disabled={!selectedBoardId}
                        style={{ width: "100%" }}

                        
                      >
                        {trelloList && <>
                        {trelloList?.map((list) => (
                          <>
                         
                          {trelloList?.length>=0?(<>
                          <Option
                            value={list?.ID}
                            key={list?.trelloListId}
                          >
                            {list?.trelloListName}
                          </Option>
                          </>):(<>
                        <option
                            value=''
                          >
                            List is Empty
                          </option>
                        </>)}
                          </>
                        ))}</>}
                      </Select>
                      
                    </>
    )}
                  
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                </>
              )}
            </Field>
            <div style={{display:"flex",justifyContent:"center"}}>
            <Button
              disabled={!selectedListId?.value}
              mt={4}
              className="submit_right1"
              isLoading={isSubmitting}
              type="submit"
            >
              Move
            </Button>
            </div>
          </Form>
        )}
      </Formik></div>
    </div>
  );
}
