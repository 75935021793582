import React, {useState,useEffect} from "react";
import "../../../Styles/Master.scss";
import { Card, Input,Spin,Button,Table,Space, Tag, Select,DatePicker} from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import { Icons } from "../../../Utils";
import { useHistory } from "react-router-dom";
import { Loader} from "../../../Components";
import { DateRangePicker } from "react-dates";
import { API } from "../../../Services";
import moment from 'moment';


export default function TransectionList(props) {
    const [formData,setFormData] = useState({})
    const { RangePicker } = DatePicker;
  const state = useSelector((state)=>state)
  const dispatch = useDispatch();
  const [data,setData] = useState({});
  const history = useHistory();
  const [tableData,setTableData] = useState([]);
  const [current,setCurrent] =useState({
      page:1,
      size:10,
  })
  const [mainLoad,setMainLoad] = useState(true);
  const { transectionList,customersList,admins,brandList} = bindActionCreators(actionCreators,dispatch)
  const { Option } = Select;
  const paidViaList =  [
    { value: "reminder", label: "Payment Reminder" },
    { value: "manual_wallet", label: " Payment Request Wallet" },
    { value: "manual_order", label: "Payment Request Order" },
    { value: "flow", label: "Payment Flow" },
    { value: "commission", label: "Partner Commission" },
    { value: "link", label: "Payment Link" },
  ]; 
   

  useEffect(()=>{
admins();
  brandList();
      setTimeout(()=>{
          console.log(state.transectionList.transections)
          setMainLoad((prev)=>!prev)
      },3000)
  },[])

  const columns = [
     

      {
          title:<div className="ap-fsm ap-fwmd">ID</div>,
          dataIndex:"Id",
          key:"Id",
          render:text=><div className="ap-fsm">{text}</div>,

      },
      {
          title:<div className="ap-fsm ap-fwmd">Customer Name</div>,
          dataIndex:"customerName",
          key:"customerName",
          render:text=><div className="ap-fsm">{text}</div>,
      }, 
      {
        title:<div className="ap-fsm ap-fwmd">Assigned To</div>,
        dataIndex:"assignedTo",
        key:"assignedTo",
        render:text=><div className="ap-fsm">{text}</div>,
    }, 
       {
        title:<div className="ap-fsm ap-fwmd">Number</div>,
        dataIndex:"customerNumber",
        key:"customerNumber",
        render: (text) => (
          <div className="ap-fsm">{text}</div>
        ),
    },
      {
        title:<div className="ap-fsm ap-fwmd">Currency</div>,
        dataIndex:"currency",
        key:"currency",
        render:text=><div className="ap-fsm">{text}</div>,
    },
      {
          title:<div className="ap-fsm ap-fwmd">Amount</div>,
          dataIndex:"amount",
          key:"amount",
          render:text=><div className="ap-fsm">{text}</div>,
      },
    
    //   {
    //       title:<div className="ap-fsm ap-fwmd">Created At</div>,
    //       dataIndex:"createdAt",
    //       key:"createdAt",
    //       render: (text) => (
    //         // <div className="ap-fsm">{text}</div>
    //         <div className="ap-fsm">
    //           {moment(text).format("MMM D, YYYY [at] h:mm A z")}
    //         </div>
    //       ),
    //   },
      {
        title:<div className="ap-fsm ap-fwmd">Transaction Date</div>,
        dataIndex:"updatedAt",
        key:"updatedAt",
        render: (text) => (
          // <div className="ap-fsm">{text}</div>
          <div className="ap-fsm">
            {moment(text).format("MMM D, YYYY [at] h:mm A z")}
          </div>
        ),
    },
      {
        title:<div className="ap-fsm ap-fwmd">Transaction ID</div>,
        dataIndex:"transectionID",
        key:"transectionID",
        render: (text) => (
          <div className="ap-fsm">{text}</div>
        ),
    },
      {
          title:<div className="ap-fsm ap-fwmd">Paid Via</div>,
          dataIndex:"paidVia",
          key:"paidVia",
          render:text=><div className="ap-fsm">{text}</div>

      },
      {
        title:<div className="ap-fsm ap-fwmd">Gateway</div>,
        dataIndex:"gateway",
        key:"gateway",
        render:text=><div className="ap-fsm">{text}</div>

    },
      {
          title:<div className="ap-fsm ap-fwmd">Payment In</div>,
          dataIndex:"paymentIn",
          key:"paymentIn",
          render:text=><div className="ap-fsm">{text}</div>

      },
      {
        title:<div className="ap-fsm ap-fwmd">OrderCode/WalletID</div>,
        dataIndex:"CodeORId",
        key:"CodeORId",
        render:text=><div className="ap-fsm">{text}</div>

    },
      {
        title:<div className="ap-fsm ap-fwmd">Status</div>,
        dataIndex:"status",
        key:"status",
       
        render:text=> <Tag color={text === "success" ? "var(--primary-green)":"yellow"}>
        <div className="ap-fsm">{text}</div>
    </Tag>

    }
      
  ].filter(item => !item.hidden);

  

  useEffect(()=>{
      if(state.transectionList?.transections.length > 0){
          console.log(state.transectionList?.transections);
          setTableData((prev)=>{
              prev = []
              state.transectionList.transections.map((transection,Index)=>{
                  prev.push({
                      key:Index,
                      Id:transection?.ID,                      
                      customerName:`${transection?.customer?.name} `,
                      customerNumber:`+${transection?.customer?.isdCode}-${transection?.customer?.mobile}`||'NA',
                      amount:transection?.amount||'NA',   
                      transectionID:transection?.transactionID||'NA' ,                    
                      CodeORId:transection?.paymentIn=='Order'? transection?.order?.code:(transection?.customer?.walletID||'NA'),
                      currency:transection?.currency||'NA',                  
                      assignedTo:transection?.assignedTo?.name||'NA',
                      updatedAt:transection?.updatedAt||'NA',
                      paidVia:transection?.paidVia||'NA',
                      status:transection?.status||'NA',
                      paymentIn:transection?.paymentIn||'NA',
                      gateway:transection?.gateway?.name,
                    //   createdAt:transection?.createdAt

                  })
              })
              return prev
          })
      } else {
          setTableData((prev)=>{
              console.log(prev);
          })
  }
  },[state])

  const callRecords = (event) =>{
      setCurrent((prev)=>{
          prev = JSON.parse(JSON.stringify(prev))
          prev.page = prev.size === event.payload.size ? event.payload.page:1
          prev.size = event.payload.size
          return prev
      })
  }
  
  const handleSearchForm = (event) =>{
    console.log('chnage',event);

    setFormData((prev)=>{
        prev = JSON.parse(JSON.stringify(prev))
        if(event.target.name=='startDate')
       { prev[`${event.target.name}`] = moment(event.target.value._d)?.format('YYYY-MM-DD') }
       else if(event.target.name=='endDate'){
        prev[`${event.target.name}`] = moment(event.target.value._d)?.format('YYYY-MM-DD')
       }else{
        prev[`${event.target.name}`] = event.target.value
       }
        return prev
    })
}
  useEffect(()=>{
    if(props.isClient == true)
    {
        transectionList(
          `limit=${current.size}&customerID=${props?.clientID}&page=${
            current.page
          }&brand=${data.brand ? data.brand : ""} `
        );

    }else{
        let querry=''
        Object.entries(formData).map(([key,value],formDataIndex)=>{
            querry = querry + `&${key}=${value}`
        })
        console.log("querry---",querry)
        let offset = current.page === 1 ? 0 : Number(current.page) * 10 - 10
       transectionList(`limit=${current.size}${querry}&page=${current.page} `)
    }
console.log("data.customer",data.value)

  },[current])

  return (
    <>
      <div className="ap-flex ap-flex-center-center ap-w100">
        {props?.isClient == true ? (
          <> </>
        ) : (
          <div className="ap-flex ap-w100">
            <Card style={{ width: "100%" }}>
              <div className="ap-flex ap-w100 ">
                <div
                  className="ap-flex ap-flex-center-center ap-w33"
                  style={{ margin: "0 10px" }}
                >
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                    Customers
                  </div>
                  <Select
                    size="large"
                    showSearch
                    onSearch={(data) => customersList(`q=${data}`)}
                    labelInValue
                    placeholder="Select Customer"
                    style={{ width: "100%" }}
                    value={formData?.customerID}
                    loading={state.customers.loading}
                    onChange={(data) => {
                      console.log(data);
                      handleSearchForm({
                        target: { name: "customerID", ...data },
                      });
                    }}
                    notFoundContent={
                      state.customers.loading && (
                        <div className="ap-w100 ap-flex ap-flex-center">
                          <Spin size="small" />
                        </div>
                      )
                    }
                    filterOption={false}
                  >
                    {state.customers.customers.map(
                      (customer, customerIndex) => (
                        <Option value={customer.value} index={customerIndex}>
                          {customer.label}
                        </Option>
                      )
                    )}
                  </Select>
                </div>
                <div
                  className="ap-flex ap-flex-center-center ap-w33"
                  style={{ margin: "0 10px" }}
                >
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Paid via</div>
                  <Select
                    size="large"
                    showSearch
                    //   onSearch={(data)=>customersList(`q=${data}`)}
                    labelInValue
                    placeholder="Select paidVia Option"
                    style={{ width: "100%" }}
                    value={formData?.paidVia}
                    //loading={state.customers.loading}
                    onChange={(data) => {
                      console.log(data);
                      handleSearchForm({
                        target: { name: "paidVia", ...data },
                      });
                    }}
                    notFoundContent={
                      <div className="ap-w100 ap-flex ap-flex-center">
                        <Spin size="small" />
                      </div>
                    }
                    filterOption={false}
                  >
                    {paidViaList.map((customer, customerIndex) => (
                      <Option value={customer.value} index={customerIndex}>
                        {customer.label}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div
                  className="ap-flex ap-flex-center-center ap-w33"
                  style={{ margin: "0 10px" }}
                >
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                    Payment In
                  </div>
                  <Select
                    size="large"
                    showSearch
                    //   onSearch={(data)=>customersList(`q=${data}`)}
                    labelInValue
                    placeholder="Select Payment type"
                    style={{ width: "100%" }}
                    value={formData?.paymentIn}
                    //loading={state.customers.loading}
                    onChange={(data) => {
                      console.log(data);
                      handleSearchForm({
                        target: { name: "paymentIn", ...data },
                      });
                    }}
                    notFoundContent={
                      <div className="ap-w100 ap-flex ap-flex-center">
                        <Spin size="small" />
                      </div>
                    }
                    filterOption={false}
                  >
                    <Option value={"Order"} index={1}>
                      Order
                    </Option>
                    <Option value={"Wallet"} index={2}>
                      Wallet
                    </Option>
                  </Select>
                </div>
              </div>
              <div className="ap-flex ap-w100 ">
                <div
                  className="ap-flex ap-flex-center-center ap-w33"
                  style={{ margin: "0 10px" }}
                >
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                    Filter By Date Range
                  </div>
                  <span
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-start",
                    }}
                  >
                    <RangePicker
                      value={
                        formData?.startDate
                          ? [
                              moment(formData?.startDate),
                              moment(formData?.endDate),
                            ]
                          : ""
                      }
                      onChange={(momentData, Date) => {
                        handleSearchForm({
                          target: { name: "startDate", value: momentData[0] },
                        });
                        handleSearchForm({
                          target: { name: "endDate", value: momentData[1] },
                        });
                      }}
                      //format={dateFormat}
                    />
                  </span>
                </div>

                <div className="ap-flex ap-flex-center-center ap-w33">
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                    Assigned To
                  </div>
                  <Select
                    size="large"
                    showSearch
                    value={formData?.assignedTo}
                    labelInValue
                    placeholder="Select Assignee"
                    style={{ width: "100%" }}
                    loading={state.admins.loading}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(data) => {
                      console.log(data);
                      handleSearchForm({
                        target: { name: "assignedTo", ...data },
                      });
                    }}
                  >
                    {state.admins.admins.map((admin, adminIndex) => (
                      <Option value={admin.value} key={adminIndex}>
                        {admin.label}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div
                  className="ap-flex ap-flex-center-center ap-w33"
                  style={{ margin: "0 10px" }}
                >
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Status</div>
                  <Select
                    size="large"
                    showSearch
                    //   onSearch={(data)=>customersList(`q=${data}`)}
                    labelInValue
                    placeholder="Select Payment type"
                    style={{ width: "100%" }}
                    value={formData?.status}
                    //loading={state.customers.loading}
                    onChange={(data) => {
                      console.log(data);
                      handleSearchForm({ target: { name: "status", ...data } });
                    }}
                    notFoundContent={
                      <div className="ap-w100 ap-flex ap-flex-center">
                        <Spin size="small" />
                      </div>
                    }
                    filterOption={false}
                  >
                    <Option value={"success"} index={1}>
                      success
                    </Option>
                    <Option value={"fail"} index={2}>
                      failed
                    </Option>
                  </Select>
                </div>
              </div>
              <div className="ap-flex ap-w100 ">
                <div
                  className="ap-flex ap-flex-center-center ap-w33"
                  style={{ margin: "0 10px" }}
                >
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Brand</div>
                  <Select
                    size="large"
                    showSearch
                    value={formData?.brand}
                    labelInValue
                    placeholder="Select Brand"
                    style={{ width: "100%" }}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(data) =>
                      handleSearchForm({
                        target: { name: "brand", ...data },
                      })
                    }
                  >
                    {state.brandList?.brands.map((brand, adminIndex) => (
                      <Option value={brand.code}>{brand.name}</Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="ap-flex ap-w100" style={{ marginTop: "10px" }}>
                <div className="ap-flex ap-flex-center-center search-btn">
                  <Button
                    type="primary"
                    shape="round"
                    className="ap-btn-c"
                    loading={state.transectionList.loading ? <Spin /> : false}
                    icon={<Icons.Search />}
                    size="middle"
                    onClick={() =>
                      callRecords({ payload: { page: 1, size: 10 } })
                    }
                  >
                    <span className="ap-fesm ap-fwmd">Search</span>
                  </Button>
                </div>
                <div
                  className="ap-flex ap-flex-center-center search-btn"
                  style={{ marginLeft: "10px" }}
                >
                  <Button
                    type="primary"
                    shape="round"
                    className="ap-btn-c"
                    loading={state.transectionList.loading ? <Spin /> : false}
                    icon={<Icons.Sweep />}
                    size="middle"
                    onClick={() => {
                      setFormData({});
                      setCurrent({ page: 1, size: 10 });
                      //  transectionList(`limit=10&offset=0&q=`)
                    }}
                  >
                    <span className="ap-fesm ap-fwmd">Clear</span>
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        )}

        {!state.transectionList.loading ? (
          <div className="ap-flex ap-w100" style={{ marginTop: "20px" }}>
            <Card style={{ width: "100%" }}>
              {props?.isClient == true ? (
                <>
                  {" "}
                  <div
                    className="ap-flex ap-w100 ap-fmd ap-fwmd"
                    style={{ marginBottom: "5px" }}
                  >
                    Client Transactions
                  </div>
                </>
              ) : (
                <div
                  className="ap-flex ap-w100 ap-fsm ap-fwmd ap-flex-end rst-record"
                  onClick={() => {
                    setData({ key: "", value: "" });
                    transectionList(`limit=10&offset=0&q=`);
                  }}
                >
                  Reset Records
                </div>
              )}

              <Table
                columns={columns}
                dataSource={tableData}
                scroll={{ x: 900 }}
                pagination={{
                  current: current.page,
                  pageSize: current.size,
                  position: ["bottomLeft"],
                  total: state.transectionList.totalRecords,
                  onChange: (data, size) =>
                    callRecords({
                      type: "page",
                      payload: { page: data, size: size },
                    }),
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
              />
            </Card>
          </div>
        ) : (
          <div
            className="ap-flex ap-w100 ap-flex-center-center"
            style={{ height: "300px" }}
          >
            <Spin tip="Fetching Records....." />
          </div>
        )}
      </div>
    </>
  );

}
