import { axiosInstance } from ".."

const brandList = async (querry = "") => {
  return new Promise(async (resolve, reject) => {
    axiosInstance
      .get(`public/brands`)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res);
        }
        return resolve(res);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export default brandList;