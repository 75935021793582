import React, { useEffect, useState } from "react";
import "./Client.scss";
import {
  Button,
  useMediaQuery,
  Select,
  ModalHeader,
  CloseButton,
} from "@chakra-ui/react";
import { Card, Skeleton } from "antd";
import { Icons } from "../../../Utils";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import { useParams, useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { API } from "../../../Services";
import { EditDetails } from "./EditDetails";
import { Orders } from "./Orders";
import { Projects } from "./Projects";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import SendToPartner from "./SendToPartner";
import { TransectionsList } from "../../Transaction/TransactionsList";
import SitesList from "../../Sites/SitesList/SitesList";

const Client = (props) => {
  const [isCurrencyEditable, setIsCurrencyEditable] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [mainLoad, setMainLoad] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState("");
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [isTablet] = useMediaQuery("(max-width:768px)");
  const [table, setTable] = useState("projects");
  const {
    customer,
    customerWallet,
    currency,
    country,
    states,
    projectsList,
    orders,
    admins,
    partnerDetails,
  } = bindActionCreators(actionCreators, dispatch);
  const history = useHistory();
  const { client_id } = useParams();

  useEffect(() => {
    customerWallet(client_id);
    customer(client_id);
    partnerDetails(client_id);
    country();
    admins();
  }, []);

  const handleCurrencyChange = () => {
    if (!isCurrencyEditable) {
      currency(state.customer.customer?.Country);
      setIsCurrencyEditable(true);
      setCurrentCurrency(state.customer.customer?.currency?.code);
    } else {
      setMainLoad((prev) => !prev);
      API.updateCurrency({
        currency: currentCurrency,
        userid: client_id,
      })
        .then((res) => {
          setMainLoad((prev) => !prev);
          setIsCurrencyEditable(true);
          customer(client_id);
          toast.success("Currency is changed successfully.", {
            position: "top-center",
          });
        })
        .catch((err) => {
          setMainLoad((prev) => !prev);
          toast.error("Oops! currency is not changed.", {
            position: "top-center",
          });
        });
    }
  };

  return (
    <div className="ap-flex ap-w100 ap-flex-center-center client">
      {/* header */}
      <div className="ap-flex ap-w100 ap-flex-space-bw">
        <div className="ap-flex ap-flex-space-bw" style={{ width: "200px" }}>
          <div className="ap-flex ap-flex-center-center ap-fmd ap-fwmd">
            Client details
          </div>
          <div className="ap-flex ap-flex-center-center">
            <EditDetails
              states={states}
              customer={customer}
              mainLoad={mainLoad}
              state={state}
            />
          </div>
        </div>
      </div>
      {/* header */}

      {/* details and actions */}
      <div
        className={`ap-flex ap-w100 ${
          isTablet ? "ap-flex-center-center" : "ap-flex-space-bw"
        }`}
        style={{ marginTop: "15px" }}
      >
        <div className={`ap-flex ${isTablet ? "ap-w100" : "ap-w60"}`}>
          <Card size="small" style={{ width: "100%" }}>
            {state.customer.customer && !state.customer.loading ? (
              Object.entries(state.customer.customer).map(
                ([key, value], index) =>
                  [
                    "Id",
                    "Name",
                    "Email",
                    "Contact Number",
                    "managerId",
                    "City",
                    "State",
                    "Country",
                    "Currency",
                  ].includes(key) && (
                    <>
                      <div
                        className="ap-flex w100 ap-flex-space-bw"
                        key={index}
                        style={{ marginBottom: "7px" }}
                      >
                        <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                          {key == "managerId" ? "Manager" : key}
                        </div>
                        <div
                          className={`ap-flex  ap-fesm ap-fwmd ${
                            isCurrencyEditable ? "select" : ""
                          }`}
                        >
                          {isCurrencyEditable && key === "Currency" ? (
                            !state.currencies.loading ? (
                              <Select
                                placeholder="Please select currency"
                                variant="filled"
                                size="xs"
                                value={currentCurrency}
                                onChange={(event) => {
                                  setCurrentCurrency(event.target.value);
                                }}
                              >
                                {state.currencies.currencies.map(
                                  (currency, currencyIndex) => (
                                    <option
                                      value={currency.value}
                                      key={currencyIndex}
                                    >
                                      {currency.label}
                                    </option>
                                  )
                                )}
                              </Select>
                            ) : (
                              <Skeleton.Input
                                active
                                style={{ width: "200px", height: "25px" }}
                              />
                            )
                          ) : key == "managerId" ? (
                            `${value?.name} ${value?.lastname}`
                          ) : (
                            value
                          )}
                          {key === "Currency" && (
                            <>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <Button
                                size="xs"
                                className="submit_right1"
                                leftIcon={<Icons.Edit />}
                                onClick={() => {
                                  handleCurrencyChange();
                                }}
                                isLoading={state.currencies.loading || mainLoad}
                              >
                                Change
                              </Button>
                              {isCurrencyEditable && (
                                <>
                                  &nbsp;&nbsp;
                                  <Button
                                    size="xs"
                                    colorScheme="red"
                                    leftIcon={<Icons.Close />}
                                    onClick={() => {
                                      setIsCurrencyEditable(false);
                                    }}
                                    isLoading={
                                      state.currencies.loading || mainLoad
                                    }
                                  >
                                    Cancel
                                  </Button>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )
              )
            ) : (
              <Skeleton active />
            )}
            <div
              className="ap-flex w100 ap-flex-space-bw"
              style={{ marginBottom: "7px" }}
            >
              {!state.customerWallet.loading ? (
                <>
                  <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                    Wallet Amount
                  </div>
                  <div className="ap-flex ap-flex-center-center ap-fesm ap-fwmd">
                    {state.customerWallet.wallet
                      ? state.customerWallet.wallet?.balance
                      : 0}
                  </div>
                </>
              ) : (
                <Skeleton.Input
                  active
                  style={{ width: "300px", height: "15px", marginTop: "20px" }}
                />
              )}
            </div>

            <div
              className="ap-flex w100 ap-flex-space-bw"
              style={{ marginBottom: "7px" }}
            >
              {state?.partnerDetails.details ? (
                <>
                  <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                    Assigned Partner
                  </div>
                  <div className="ap-flex ap-flex-center-center ap-fesm ap-fwmd">
                    {state.partnerDetails.details
                      ? state.partnerDetails.details.firstname +
                        " " +
                        state.partnerDetails.details.lastname
                      : "NA"}
                  </div>
                </>
              ) : (
                <Skeleton.Input
                  active
                  style={{ width: "300px", height: "15px", marginTop: "20px" }}
                />
              )}
            </div>
          </Card>
        </div>
        <div className={`ap-flex ${isTablet ? "ap-w100" : "ap-w35"}`}>
          <Card size="small" style={{ width: "100%", height: "fit-content" }}>
            <div className="ap-flex ap-w100 ap-flex-center-center">
              <Button
                size="md"
                className="submit_right1"
                onClick={() => {
                  history.push("/home/ap/clients/list");
                }}
                width={"250px"}
                maxWidth={"100%"}
                isLoading={mainLoad}
              >
                Client List
              </Button>
            </div>
            <div
              className="ap-flex ap-w100 ap-flex-center-center"
              style={{ marginTop: "5px" }}
            >
              <Button
                size="md"
                className="submit_right1"
                onClick={() => {
                  history.push(
                    `/home/ap/payment/add/wallet/${state.customer.customer?.country}/${client_id}/${state.customerWallet.wallet?.ID}`
                  );
                }}
                width={"250px"}
                maxWidth={"100%"}
                leftIcon={<Icons.Plus />}
                disabled={state.customerWallet.wallet === null}
                isLoading={mainLoad}
              >
                Add Amount to Wallet
              </Button>
            </div>
            <div
              className="ap-flex ap-w100 ap-flex-center-center"
              style={{ marginTop: "5px" }}
            >
              <Button
                size="md"
                className="submit_right1"
                onClick={() => {
                  history.push(`/home/ap/client/wallent/ledger/${client_id}`);
                }}
                width={"250px"}
                maxWidth={"100%"}
                isLoading={mainLoad}
              >
                Wallet Ledger
              </Button>
            </div>
            {!state.partnerDetails.details ? (
              <div
                className="ap-flex ap-w100 ap-flex-center-center"
                style={{ marginTop: "5px" }}
              >
                <Button
                  size="md"
                  className="submit_right1"
                  onClick={() => {
                    onOpen();
                  }}
                  width={"250px"}
                  maxWidth={"100%"}
                  // isLoading={mainLoad}
                >
                  Send To Partner
                </Button>
              </div>
            ) : (
              ""
            )}
          </Card>
        </div>
      </div>
      {/* details and actions */}
    
      {/* table actions */}
      <div className="ap-flex ap-w100" style={{ marginTop: "15px" }}>
        <div className="ap-flex ap-flex-center-center">
          <Button
            size="sm"
            className={
              table === "projects" ? "submit_right1" : "submit_right_outline"
            }
            onClick={() => {
              setTable("projects");
            }}
            isLoading={mainLoad}
          >
            Projects
          </Button>
        </div>
        <div
          className="ap-flex ap-flex-center-center"
          style={{ marginLeft: "10px" }}
        >
          <Button
            size="sm"
            className={
              table === "orders" ? "submit_right1" : "submit_right_outline"
            }
            onClick={() => {
              setTable("orders");
            }}
            isLoading={mainLoad}
          >
            Orders
          </Button>
        </div>
        <div
          className="ap-flex ap-flex-center-center"
          style={{ marginLeft: "10px" }}
        >
          <Button
            size="sm"
            className={
              table === "transections"
                ? "submit_right1"
                : "submit_right_outline"
            }
            onClick={() => {
              setTable("transections");
            }}
            isLoading={mainLoad}
          >
            Transactions
          </Button>
        </div>
      
        <div
          className="ap-flex ap-flex-center-center"
          style={{ marginLeft: "10px" }}
        >
          <Button
            size="sm"
            className={
              table === "sites" ? "submit_right1" : "submit_right_outline"
            }
            onClick={() => {
              setTable("sites");
            }}
            isLoading={mainLoad}
          >
            Sites
          </Button>
        </div>
      </div>
      {/* table actions */}

      {/* Table action */}
      <div className="ap-flex ap-w100" style={{ marginTop: "10px" }}>
        {table === "orders" && <Orders state={state} orders={orders} />}
        {table === "projects" && (
          <Projects state={state} projectsList={projectsList} />
        )}
        {table === "transections" && (
          <TransectionsList isClient={true} clientID={client_id} />
        )}
       
        {table === "sites" && (
          <SitesList isClient={true} clientID={client_id} />
        )}
      </div>
      {/* Table action */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="50rem" maxH="38rem" minH="15rem">
          <SendToPartner
            onClose={onClose}
            customerId={state?.customer?.customer?.Id}
            clientID={client_id}
          />
        </ModalContent>
      </Modal>
     
    </div>
  );
};

export default Client;
