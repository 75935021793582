const brandListReducer = (
  state = {
    loading: false,
    brands: [],
  },
  action
) => {
  switch (action.type) {
    case "FETCH_BRAND_LIST":
      return { ...state, loading: true, brands: [] };
    case "RECEIVE_BRAND_LIST":
      return { ...state, brands: [...action.payload], loading: false };
    case "ERROR_BRAND_LIST":
      return { ...state, brands: [], loading: true };
    default:
      return { ...state };
  }
};

export default brandListReducer;