
import styled from "styled-components";

export const  TButton = styled.button`
  background-color: #ddd !important;
  line-height: 20px;
  padding: 6px 12px !important;
  font-size: 14px !important;
  color: #000 !important;
  border-radius: 0 !important;
  border: none !important;
  text-align: left !important;

  svg {
    color: var( --primary-dark-background);
    font-size: 18px;
    padding-bottom: 2px;
  }
`;
export const  TCardHeding = styled.span`

h2{
    font-size: 20px;
    color: #172b4d;
    font-weight: 600;
}
}
`;
export const  DescriptionCont = styled.div`
  .fa {
    font-size: 22px !important;
  }
  h2 {
      padding:10px 0;
    span {
      font-weight: bolder;
      font-size: 22px;
    }
  }
`;
export const  AttachmentCont = styled.div`
  padding: 10px 0;
  h2 {
      padding:10px 0;
      svg{
        padding-bottom: 3px;
        color:#555;
      }
    span {
      font-weight: bolder;
      font-size: 22px;
    }
  }
  .file-title{
      color:black;
    font-weight: bolder;
  }
.file-add-date{

    color: #6c757d!important;
}
.view-attachment{
    padding:25px;
    cursor: pointer;
    text-decoration: underline;
    color:#6c757d!important;
    :hover{
        text-decoration:none;
        color:#6c757d!important;
    }
}
.file-icon
{ svg{
   font-size: 30px;
   color: #ccc;
 }}
`;
export const ActionButons = styled.div`
padding:0 8px;
`

export const TrelloComments = styled.div`
width:100%;
padding:15px;

.fa {
  font-size: 22px !important;
}
h2 {
    padding:10px 0;
    svg{
      padding-bottom: 3px;
      color:#555;
    }
  span {
    font-weight: bolder;
    font-size: 22px;
  }
}
.comment-div{
     padding:10px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    border-radius: 0.25rem!important;
    border: 1px solid #dee2e6!important;
}
`
export const DeleteBtn= styled.div`
align-items: center;
cursor:pointer;
&:hover{
svg{
  color:#ef6161;
}
}
`
export const UserAvtar= styled.div`
height: 40px;
    width: 40px;
    background: #CE6A6B;
    border-radius: 24px;
    text-align: center;
    padding-top: 0px;
    margin-top: 5px;
    line-height: 40px;
    font-weight: 600;
    color: #fff;

    .imgDiv{
      border-radius: 24px;
    height: 40px;
    width: 40px;
    object-fit: cover;
    }
`

export const TagUserAvtar = styled.div`
  height: 20px;
  width: 20px;
  ${'' /* background: #ce6a6b; */}
  border-radius: 24px;
  text-align: center;
  padding-top: 0px;
  margin-top: 5px;
  line-height: 20px;
  font-weight: 600;
  color: #fff;
  .tag-member-initials {
    cursor: pointer; 
    font-size:10px;
  }
  .imgDiv {
    border-radius: 24px;
    height: 20px;
    width: 20px;
    object-fit: cover;
  }
`;
export const MemberDiv = styled.div`
  .delete-btn {
    position: absolute;
    top: 2px;
    left: 35px ;
    transition: opacity 0.3s ease;
  }

 

  .member-initials {
    cursor: pointer; /* Add this if you want to show the delete button on hover */
  }

  .member-initials:hover + .delete-btn {
    opacity: 1;
  }
`;

export const LoaderDiv = styled.div`
height: 60px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

`

export const FileTypeDiv = styled.div`
background-color: rgb(248, 249, 250);
    align-items: center;
    padding: 15px;
svg{
  font-size: 45px;
  color: #ccc;

}


`

export const CommentDiv = styled.div`
background-color: rgb(248, 249, 250);
padding:5px 8px;
border: 1px solid #80808042;
border-radius: 4px;
color: gray;
a{
  color:#0000ffa3 !important;
  &:hover {
    text-decoration: underline;
  }
}

p{
  color:gray;
}

.mark-up-to-html{
  img{
    max-width: 300px;
  }
}

`
export const ScrollButton = styled.div`
   position: fixed; 
   width: 100%;
   left: 50%;
   bottom: 40px;
   height: 20px;
   font-size: 3rem;
   z-index: 1;
   cursor: pointer;
   color: var(--primary-dark-background);

    .MuiSvgIcon-root{
    fill: currentColor;
    border-radius: 3%;
    width: 1.5em;
    height: 1.5em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    background-color: var( --primary-green);
    user-select: none;
   }
`
export const CommentQuillDiv= styled.div`
margin:1.1rem;
p{

  color:#000 !important;
}
`