import React, { useState,useEffect } from "react";
import "./DefaultLayout.scss";
import { Layout, Menu,Avatar,Popover } from 'antd';
import { Icons } from "../../Utils";
import BrandLogo from "../../Assets/Images/new_brand_logo.png";
import { Link,useRouteMatch,useHistory, useLocation } from "react-router-dom";
import Swal from 'sweetalert2';

const DefaultLayout = (props) =>{
    const [collapsed,setCollapsed]=useState(false);
    const [width,setWidth] = useState(250);
    const { Header, Content, Footer, Sider } = Layout;
    const { SubMenu } = Menu;
    let match = useRouteMatch("/home/ap")
    const history = useHistory();
    const location = useLocation();
    const [adminDetails,setAdminDetails] = useState(JSON.parse(localStorage.getItem("adminDetails")))

    useEffect(()=>{
      let w=240
      if(collapsed){
        w=64
      }
      setWidth((prev)=>w)
    },[collapsed])

    const handleLogout = () =>{
      Swal.fire({
        position: 'center',
        type: 'error',
        title: 'Are you sure?',
        text: 'You want to logout !',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    }).then((result) => {
        if (result.value) {
            localStorage.clear()
            history.push("/")
        }
    })
    }

    return (
      <>
        {match && (
          <>
            <div
              className="new-aside"
              style={{ width: `${collapsed ? "64px" : "250px"}` }}
            >
              <Sider
                collapsible
                collapsed={collapsed}
                onCollapse={() => setCollapsed((prev) => !prev)}
                width="250px"
              >
                <Menu
                  theme="dark"
                  defaultSelectedKeys={["1"]}
                  mode="inline"
                  style={{ marginTop: "64px" }}
                >
                  <Menu.Item key="1" icon={<Icons.Dashboard />}>
                    <Link to="/home/ap/dashboard">
                      <span
                        className="ap-fesm ap-fwmd ap-lsp"
                        style={{ color: "#fff" }}
                      >
                        Dashboard
                      </span>
                    </Link>
                  </Menu.Item>

                  <SubMenu
                    key="sub1"
                    icon={<Icons.Project />}
                    title={
                      <span
                        className="ap-fesm ap-fwmd ap-lsp"
                        style={{ color: "#fff" }}
                      >
                        Projects
                      </span>
                    }
                  >
                    <Menu.Item
                      key="2"
                      icon={<Icons.List />}
                      style={{ display: "none" }}
                    >
                      <Link to="/home/ap/projects/master/list">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          Master Projects List
                        </span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<Icons.List />}>
                      <Link to="/home/ap/projects/list">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          Projects List
                        </span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                  <SubMenu
                    key="sub2"
                    icon={<img src={Icons.Orders} width="16px" />}
                    title={
                      <span
                        className="ap-fesm ap-fwmd ap-lsp"
                        style={{ color: "#fff" }}
                      >
                        Orders
                      </span>
                    }
                  >
                    <Menu.Item
                      key="5"
                      icon={<Icons.Plus />}
                      style={{ display: "none" }}
                    >
                      <a
                        href="https://www.makemyhouse.com/dashboard_user/add_manual_order"
                        target="_blank"
                      >
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          Create Manual Order
                        </span>
                      </a>
                    </Menu.Item>
                    <Menu.Item key="6" icon={<Icons.List />}>
                      <Link to="/home/ap/orders/list">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          Orders List
                        </span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="7" icon={<Icons.Chat />}>
                      <Link to="/chat" target="_blank">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          Orders Chat
                        </span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="sub3"
                    icon={<Icons.Draft />}
                    title={
                      <div
                        className="ap-fesm ap-fwmd ap-lsp"
                        style={{ color: "#fff" }}
                      >
                        Draft Orders
                      </div>
                    }
                  >
                    <Menu.Item key="8" icon={<Icons.Plus />}>
                      <Link to="/home/ap/project/select/do">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          Create Draft Order
                        </span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="9" icon={<Icons.List />}>
                      <Link to="/home/ap/draft/orders/list">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          Draft Orders List
                        </span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="sub4"
                    icon={<img src={Icons.Custom} />}
                    title={
                      <span
                        className="ap-fesm ap-fwmd ap-lsp"
                        style={{ color: "#fff" }}
                      >
                        Custom Orders
                      </span>
                    }
                  >
                    <Menu.Item key="10" icon={<Icons.Plus />}>
                      <Link to="/home/ap/project/select/co">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          Create Custom Order
                        </span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="11" icon={<Icons.List />}>
                      <Link to="/home/ap/custom/orders/list">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          Custom Orders List
                        </span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="sub5"
                    icon={<Icons.UserGroup />}
                    title={
                      <span
                        className="ap-fesm ap-fwmd ap-lsp"
                        style={{ color: "#fff" }}
                      >
                        Clients
                      </span>
                    }
                  >
                    <Menu.Item key="12" icon={<Icons.List />}>
                      <Link to="/home/ap/clients/list">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          Clients List
                        </span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="13" icon={<Icons.Chat />}>
                      <Link to="/leadchat" target="_blank">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          Clients Chat
                        </span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="sub9"
                    icon={<Icons.UserGroup />}
                    title={
                      <span
                        className="ap-fesm ap-fwmd ap-lsp"
                        style={{ color: "#fff" }}
                      >
                        Partners
                      </span>
                    }
                  >
                    <Menu.Item key="24" icon={<Icons.List />}>
                      <Link to="/home/ap/partners/list">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          Partners List
                        </span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="sub6"
                    icon={<Icons.Payment />}
                    title={
                      <span
                        className="ap-fesm ap-fwmd ap-lsp"
                        style={{ color: "#fff" }}
                      >
                        Payments
                      </span>
                    }
                  >
                    <Menu.Item key="14" icon={<Icons.Plus />}>
                      <Link to="/home/ap/payment/request/generate">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          Generate Request Link
                        </span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="15" icon={<Icons.List />}>
                      <Link to="/home/ap/payment/requests/list">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          Payment Requests List
                        </span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="28" icon={<Icons.List />}>
                      <Link to="/home/ap/payment/requests/link/list">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          Payment Link List
                        </span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="26" icon={<Icons.Transaction />}>
                      <Link to="/home/ap/transaction-list">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          Transaction List
                        </span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="sub7"
                    icon={<img src={Icons.Discount} />}
                    title={
                      <span
                        className="ap-fesm ap-fwmd ap-lsp"
                        style={{ color: "#fff" }}
                      >
                        Promo
                      </span>
                    }
                  >
                    <Menu.Item key="16" icon={<Icons.Plus />}>
                      <Link to="/home/ap/promo/create">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          Create Promo
                        </span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="17" icon={<Icons.List />}>
                      <Link to="/home/ap/promos/list">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          Promo List
                        </span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="sub8"
                    icon={<img src={Icons.Task} />}
                    title={
                      <span
                        className="ap-fesm ap-fwmd ap-lsp"
                        style={{ color: "#fff" }}
                      >
                        Tasks
                      </span>
                    }
                  >
                    <Menu.Item key="18" icon={<Icons.Plus />}>
                      <Link to="/home/ap/task/add">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          Add Task
                        </span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="19" icon={<Icons.List />}>
                      <Link to="/home/ap/tasks/list">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          Tasks List
                        </span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                  {/* <Menu.Item key="20" icon={<Icons.Message />}>
              <Link to="/home/ap/sms/send">
                <span className="ap-fesm ap-fwmd ap-lsp" style={{color:"#fff"}}>
                  Send SMS
                </span>
              </Link>
            </Menu.Item> */}

                  <Menu.Item key="22" icon={<Icons.Project />}>
                    <Link to="/home/ap/trello/list">
                      <span
                        className="ap-fesm ap-fwmd ap-lsp"
                        style={{ color: "#fff" }}
                      >
                        Design PMS
                      </span>
                    </Link>
                  </Menu.Item>
                  <SubMenu
                    key="sub10"
                    icon={<Icons.Catalog />}
                    title={
                      <span
                        className="ap-fesm ap-fwmd ap-lsp"
                        style={{ color: "#fff" }}
                      >
                        Project Listing
                      </span>
                    }
                  >
                    <Menu.Item key="25" icon={<Icons.List />}>
                      <Link to="/home/ap/local-catalog/list">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          MMH Project Listing
                        </span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="29" icon={<Icons.List />}>
                      <Link to="/home/ap/catalog/list">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          2D Project Listing
                        </span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="37" icon={<Icons.List />}>
                      <Link to="/home/ap/3d-utec-project/list">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          3D Project Listing{" "}
                        </span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="36" icon={<Icons.List />}>
                      <Link to="/home/ap/interior-design/list">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          Interior Design Listing
                        </span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="sub11"
                    icon={<Icons.Settings />}
                    title={
                      <span
                        className="ap-fesm ap-fwmd ap-lsp"
                        style={{ color: "#fff" }}
                      >
                        Control Panel
                      </span>
                    }
                  >
                    <Menu.Item key="30" icon={<Icons.List />}>
                      <Link to="/home/ap/attachement-category/list">
                        <span
                          className="ap-fesm ap-fwmd ap-lsp"
                          style={{ color: "#fff" }}
                        >
                          Attachment Category List
                        </span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  {/* <Menu.Item key="23" icon={<Icons.List />}>
            <Link to="/home/ap/projects/master">
                <span className="ap-fesm ap-fwmd ap-lsp" style={{color:"#fff"}}>
                  Master Projects List
                </span>
                </Link>
            </Menu.Item> */}
                </Menu>
              </Sider>
            </div>

            <Header
              className="site-layout-background ap-flex ap-w100 ap-flex-end"
              style={{ padding: "0 10px 0 0", backgroundColor: "#fff" }}
            >
              <div className="ap-flex ap-flex-center-center logo ">
                <Link to="/dashboard">
                  <img src={BrandLogo} alt="" width="180px" height="50px" />
                </Link>
              </div>
              <div className="ap-flex">
                <div className="ap-flex ap-flex-center-center user">
                  <Popover
                    placement="bottomLeft"
                    title={
                      <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                        {adminDetails?.firstName} {adminDetails?.lastName}
                      </div>
                    }
                    content={
                      <Link to={""}>
                        <div className="ap-flex ap-w100 ap-fesm ap-fwmd cp">
                          Change Password
                        </div>
                      </Link>
                    }
                    trigger="hover"
                  >
                    <Avatar
                      className="ap-flex ap-flex-center-center"
                      style={{
                        backgroundColor: "var(--primary-light-background)",
                      }}
                      icon={<Icons.User />}
                    />
                  </Popover>
                </div>
                <div
                  className="ap-flex ap-flex-center-center ap-fsm ap-fwmd"
                  style={{ marginRight: "20px" }}
                >
                  Welcome {adminDetails?.firstName} {adminDetails?.lastName}
                </div>
                <div
                  className="ap-flex ap-flex-center-center logout"
                  onClick={() => handleLogout()}
                >
                  <img src={Icons.Logout} alt="" width="30px" height="30px" />
                </div>
              </div>
            </Header>

            <Content
              className="ap-flex ap-w100 ap-flex-end"
              style={{ marginTop: "64px" }}
            >
              <div style={{ padding: 20, width: `calc(100% - ${width}px)` }}>
                {props.children ? props.children : ""}
              </div>
              {/* <Footer style={{ textAlign: 'center',width:"100%",paddingLeft:"250px",position:"absolute",bottom:"0" }}>Copyrights &#169; 2020 makemyhouse.com, Manson's Mark Pvt Ltd Venture. All Rights Reserved. </Footer> */}
            </Content>
          </>
        )}
      </>
    );
}

export default DefaultLayout;